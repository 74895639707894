import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, Credentials } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;

  public error: string = null;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  onSubmit(): void {
    this.error = null;
    if (this.loginForm.valid) {
      const credentials: Credentials = {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
      };

      this.authService.login(credentials).subscribe(
        () => {
          const isNotPileje = this.authService.checkIfNotPileje(false);
          if (isNotPileje) {
            this.router.navigate(['dashboard']);
          } else {
            this.router.navigate(['pileje/practitioners']);
          }
        },
        () => {
          this.error = 'Identifiants incorrects';
        }
      );
    }
  }
}
