import { environment } from '../../../environments/environment';
import { RequestDataType } from './../pages/activation-key/models/request-data-type';
import { PackageData } from './../pages/activation-key/models/package-data.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivationKeyService {
  private commercialURL = environment.api.commercialUrl;
  package_data = new BehaviorSubject<PackageData>(null);
  ordered_package = new BehaviorSubject<any>(false);
  error_handler = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  updatePackageData(data: PackageData): void {
    this.package_data.next(data);
  }
  createCampaign(values) {
    return this.http.post(this.commercialURL + '/campaign/create', values);
  }
  generatePackage(values: RequestDataType) {
    return this.http.post(this.commercialURL + '/activationkeys', values);
  }
  packageIsReady() {
    return this.ordered_package.asObservable();
  }
  createCompany(companyObject) {
    return this.http.post(
      this.commercialURL + '/companies/create',
      companyObject
    );
  }

  getAllKeyRequests() {
    return this.http.get(this.commercialURL + '/campaign/get-all');
  }

  setKeyRequest(body) {
    return this.http.post(this.commercialURL + '/key-requests/create', body);
  }
}
