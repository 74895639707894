import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Patient, UpdatePatientDto } from '../../../core/models/patient.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { PasswordValidator } from '../../../core/validators/password.validator';
import { DateValidator } from '../../../core/validators/date.validator';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-edit-patient-form',
  templateUrl: './edit-patient-form.component.html',
  styleUrls: ['./edit-patient-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditPatientFormComponent implements OnInit {
  public $patient = new BehaviorSubject<Patient>(null);
  @Input()
  set patient(value) {
    this.$patient.next(value);
  }
  get patient(): Patient {
    return this.$patient.getValue();
  }

  @Input() public translation: string;
  @Input() public passwordRequired: boolean;
  @Output() formValues = new EventEmitter<UpdatePatientDto>();
  @Output() submitError = new EventEmitter<string>();

  public patientForm: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.initForm();
    this.setFormValues();
    this.setPasswordValidators();
  }

  initForm(): void {
    this.patientForm = new FormGroup({
      gender: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      socialNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      dateOfBirth: new FormControl('', [
        Validators.required,
        DateValidator.valid,
      ]),
      phoneNumber: new FormControl('', [Validators.required]),
      passwords: new FormGroup({
        password: new FormControl(''),
        confirmationPassword: new FormControl(''),
      }),
    });
  }

  setFormValues(): void {
    console.log(this.$patient, 'patient');
    this.$patient
      .pipe(
        takeWhile((patient) => patient === undefined || patient === null, true)
      )
      .subscribe((patient) => {
        if (patient) {
          let formatedDate = '';
          if (patient.dateOfBirth || patient.enabled) {
            formatedDate = formatDate(patient.dateOfBirth, 'dd/MM/yyyy', 'fr');
          }
          this.patientForm.patchValue({
            gender: patient.gender,
            firstName: patient.firstName,
            lastName: patient.lastName,
            socialNumber: patient.socialNumber,
            email: patient.email,
            phoneNumber: patient.phoneNumber,
            dateOfBirth: formatedDate,
          });
        }
      });
  }

  getFormValue(name: string): string {
    return this.patientForm.get(name).value;
  }

  setPasswordValidators(): void {
    const passwordsGroup: FormGroup = this.patientForm.get(
      'passwords'
    ) as FormGroup;
    PasswordValidator.setValidators(
      passwordsGroup,
      'password',
      'confirmationPassword',
      this.passwordRequired
    ).subscribe();
  }

  handleSubmit(): void {
    if (this.patientForm.valid) {
      const formatedDateOfBirth = DateValidator.formatDateOfBirth(
        this.patientForm.value.dateOfBirth
      );
      const values = {
        gender: this.patientForm.value.gender,
        firstName: this.patientForm.value.firstName,
        lastName: this.patientForm.value.lastName,
        email: this.patientForm.value.email,
        socialNumber: this.patientForm.value.socialNumber,
        phoneNumber: this.patientForm.value.phoneNumber,
        dateOfBirth: formatedDateOfBirth,
        password: this.patientForm.value.passwords.password,
        confirmationPassword: this.patientForm.value.passwords
          .confirmationPassword,
      };
      this.formValues.emit(values);
    }
  }
}
