import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { ExportService } from "../../services/export.service";
import { ExportsDatepickerComponent } from "../../components/exports-datepicker/exports-datepicker.component";

@Component({
  selector: "app-exports-exalis",
  templateUrl: "./exports-exalis.component.html",
  styleUrls: ["./exports-exalis.component.scss"]
})

export class ExportsExalisComponent implements OnInit {
  
  
  constructor(
    public dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly exportService: ExportService,
  ) {
    
  }

  ngOnInit(): void {
  
}




downloadKpiDataExalisWithDateRange(): void {
  const dialogReference = this.dialog.open(ExportsDatepickerComponent, {
    data: { validationButtonLabel : 'Exporter' }
  });

  dialogReference.afterClosed().subscribe(periodPicked => {
    if (periodPicked) {
      periodPicked = JSON.parse(periodPicked);

  this.exportService.getDownloadableKpiDataExalisWithDateRange(periodPicked).subscribe(data => {
    const filename = this.generateFilename('Indicateurs de suivi Exalis avec période', periodPicked);
    this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
  }, error => this.handleHttpError(error));
}  
});
}



  downloadUserPortalStatsThroughDatePicker(): void {
    const dialogReference = this.dialog.open(ExportsDatepickerComponent, {
      data: { validationButtonLabel : 'Exporter' }
    });

    dialogReference.afterClosed().subscribe(periodPicked => {
      if (periodPicked) {
        periodPicked = JSON.parse(periodPicked);
        this.exportService.getDownloadableUserPortalStats(periodPicked).subscribe(data => {
          const filename = this.generateFilename('Utilisations portail', periodPicked);
          this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
        }, error => this.handleHttpError(error));
      }
    });
  }


  downloadKpiDataExalis(): void {
    this.exportService.getDownloadableKpiDataExalis().subscribe(data => {
      const filename = this.generateFilename('Indicateurs de suivi Exalis');
      this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
    }, error => this.handleHttpError(error));
  }
  // FIXME
  //  Title should be obtainable from response header 'Content-Disposition' but is currently always
  //  null... Adding allowed headers in backend didn't solve the issue
  private generateFilename(context: string, period = null): string {
    let formattedDate = '';
    if (!period) {
      const now = new Date();
      formattedDate = `${now.toLocaleDateString('fr-FR')}`;
    } else {
      formattedDate = `${new Date(period.start).toLocaleDateString('fr-FR')}-${new Date(period.end).toLocaleDateString('fr-FR')}`;
    }

    return `[Export Numa] ${context} - ${formattedDate}.csv`;
  }

  private triggerDownload(data, type, filename): void {
    const blob = new Blob([data], { type: type });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.download = filename;
    anchor.href = url;
    anchor.click();
  }

  private handleHttpError(error): void {
    let errorMessage = JSON.parse(error.error);
    errorMessage = errorMessage.message ? errorMessage.message : error.statusText;

    this.snackBar.open(
      `Une erreur est survenue lors de la génération de l'export : ${errorMessage}`,
      '',
      { verticalPosition: 'top', horizontalPosition: 'right', duration: 5000 },
    );
  }
}
