<div class="dashboard">
  <app-toolbar *ngIf="showToolbar"></app-toolbar>

  <app-praticioners-list
    [practitioners]="practitioners"
    (rowClick)="handleRowClick($event)"
  ></app-praticioners-list>
  <mat-paginator
    [length]="practitionerPagination?.meta.totalItems"
    [pageSize]="practitionerPagination?.meta.itemsPerPage"
    (page)="handlePage($event)"
  ></mat-paginator>
</div>
