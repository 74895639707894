import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import {
  User,
  UserDashboardComponent,
} from './../user-dashboard/user-dashboard.component';
import { MatTableDataSource } from '@angular/material/table';
import { Sort, MatSort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { KeyRequestService } from './../../services/key-request.service';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-key-requests',
  templateUrl: './key-requests.component.html',
  styleUrls: ['./key-requests.component.scss'],
})
export class KeyRequestsComponent implements OnInit {
  ELEMENT_DATA: User[] = [];
  displayedColumns: string[] = [
    // 'mark',
    'lastName',
    'firstName',
    'email',
    'lastDate',
    'activationKey',
    'activated',
    // 'enabled',
    'company',
    'type',
    'createdAt',
    'etc',
  ];
  dataSource: any;
  panelOpenState = false;
  lastEvaluationDate: any = 'Undefined';

  noDataFound: boolean = false;
  searchText: any;
  tableData = [];
  dataToSave = {};
  onlyMarked = false;
  onlyActivated = false;
  onlyEnabled = false;
  loading = true;
  userDataSub: Subscription;
  activationKeysDataSub: Subscription;
  dataIsReadySub: Subscription;
  dataIsSortedSub: Subscription;
  loadStoredDataSub: Subscription;
  totalRows: number;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private keyRequestService: KeyRequestService,
    private _liveAnnouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.keyRequestService.getAllKeyRequests().subscribe((data) => {
      this.dataSource = data;
    });

    this.keyRequestService.getAllKeyRequests().subscribe((usersData: any) => {
      this.keyRequestService.completeData = usersData;

      this.totalRows = this.keyRequestService.completeData.length;

      this.dataSource = new MatTableDataSource(
        Object.values(this.keyRequestService.completeData)
      );
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      this.loading = false;
    });
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  greet(event: any) {
    this.onSearch(event);
  }
  onSearch(param) {
    // this.greetEvent.emit(param);
    let filterdData = this.keyRequestService.completeData.filter((data) => {
      return (
        (data.mail_address
          ? data.mail_address
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0
          : false) ||
        (data.company_id
          ? data.company_id
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0
          : false) ||
        (data.company_name
          ? data.company_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0
          : false)
      );
    });
    if (filterdData.length > 0) {
      this.dataSource = new MatTableDataSource(Object.values(filterdData));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      this.noDataFound = false;
    } else {
      let emptyData = [
        {
          user_id: 'N/A',
          first_name: 'N/A',
          phone_number: 'N/A',
          enabled: 'N/A',
          last_name: 'N/A',
          email: 'N/A',
          ak_id: 'N/A',
          company_name: 'N/A',
          type: 'N/A',
          created_at: null,
          is_activated: 'N/A',
          star: 'N/A',
          collectionDate: 'N/A',
          note: 'N/A',
        },
      ];
      // this.noDataFound = true;
      //this.dataSource = new MatTableDataSource([]);
      //this.dataSource.sort = this.sort;
      this.dataSource = new MatTableDataSource(Object.values(emptyData));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

    if (param == '') {
      this.dataSource = new MatTableDataSource(
        Object.values(this.keyRequestService.completeData)
      );
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
}
