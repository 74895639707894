import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Practitioner } from '../../../core/models/practitioner.model';
import { PractitionerService } from '../../services/practitioner.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-practitioner-switch-status',
  templateUrl: './practitioner-switch-status.component.html',
  styleUrls: ['./practitioner-switch-status.component.scss'],
})
export class PractitionerSwitchStatusComponent implements OnInit {
  public $practitioner = new BehaviorSubject<Practitioner>(null);
  public isPilejeRoute = false;
  public showToolbar = false;

  @Input()
  set practitioner(value) {
    this.$practitioner.next(value);
  }
  get practitioner(): Practitioner {
    return this.$practitioner.getValue();
  }

  @Output() switch = new EventEmitter<string>();

  constructor(
    private practitionerService: PractitionerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const practitionerId = params.get('practitionerId');
      const url = this.route.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      this.showToolbar = url.includes(
        `pileje/practitioners/edit/${practitionerId}`
      );
      this.isPilejeRoute = this.showToolbar;
    });
  }

  handleSwitchStatus(enabled: boolean): void {
    const practitionerId = this.practitioner.id;
    const data = { enabled };

    this.practitionerService
      .updateStatus(this.practitioner, practitionerId, data)
      .subscribe(() => {
        if (enabled) {
          this.switch.emit('Activation effectuée');
        } else {
          this.switch.emit('Désactivation effectuée');
        }
      });
  }

  handleRegistration(enabled: boolean): void {
    const practitionerId = this.practitioner.id;
    const data = { enabled };

    this.practitionerService
      .updatePilejeStatus(this.practitioner, practitionerId, data)
      .subscribe(() => {
        if (enabled) {
          this.switch.emit('Activation effectuée');
        } else {
          this.switch.emit('Désactivation effectuée');
        }
      });
  }
}
