import { Component, OnDestroy, OnInit } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import {
  Patient,
  PatientPaginationDto,
} from '../../../core/models/patient.model';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SearchGlobalService } from 'src/app/core/services/search-global.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit, OnDestroy {
  public patientPagination: PatientPaginationDto;
  public patients: MatTableDataSource<Patient>;
  public isLoading = false;
  public loadingProgress = 0;

  private searchSubscription: Subscription;
  private allPatients: Patient[] = [];
  private currentSearchTerm: string = '';
  private pageSize: number = 20;
  private readonly delayBetweenRequests = 1000;

  constructor(
    private patientService: PatientService,
    private router: Router,
    private globalSearchService: SearchGlobalService
  ) {}

  ngOnInit() {
    this.loadAllPatientsWithDelay();

    this.searchSubscription = this.globalSearchService.globalSearchStorage
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.currentSearchTerm = searchTerm || '';
        this.applySearchAndPagination(0);
      });
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  private async loadAllPatientsWithDelay() {
    this.isLoading = true;
    try {
      const firstPage = await this.patientService
        .getAll(0, this.pageSize)
        .toPromise();
      const totalPages = Math.ceil(firstPage.meta.totalItems / this.pageSize);
      this.allPatients = firstPage.items;

      this.loadingProgress = Math.round((1 / totalPages) * 100);

      for (let page = 1; page < totalPages; page++) {
        try {
          await this.delay(this.delayBetweenRequests);
          const pageData = await this.patientService
            .getAll(page, this.pageSize)
            .toPromise();
          this.allPatients = [...this.allPatients, ...pageData.items];
          this.loadingProgress = Math.round(((page + 1) / totalPages) * 100);

          this.applySearchAndPagination(0);
        } catch (error) {
          if (error.status === 429) {
            await this.delay(60000);
            page--;
            continue;
          }
          throw error;
        }
      }
    } catch (error) {
      console.error('Error loading patients:', error);
    } finally {
      this.isLoading = false;
      this.loadingProgress = 100;
      this.applySearchAndPagination(0);
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private applySearchAndPagination(page: number) {
    let filteredPatients = this.allPatients;

    if (this.currentSearchTerm) {
      const searchTerm = this.currentSearchTerm.toLowerCase();
      filteredPatients = this.allPatients.filter(
        (patient) =>
          patient.firstName?.toLowerCase().includes(searchTerm) ||
          patient.lastName?.toLowerCase().includes(searchTerm) ||
          patient.email?.toLowerCase().includes(searchTerm)
      );
    }

    const startIndex = page * this.pageSize;
    const paginatedPatients = filteredPatients.slice(
      startIndex,
      startIndex + this.pageSize
    );

    const totalItems = filteredPatients.length;
    const totalPages = Math.ceil(totalItems / this.pageSize);

    this.patientPagination = {
      items: paginatedPatients,
      meta: {
        currentPage: page,
        itemCount: paginatedPatients.length,
        itemsPerPage: this.pageSize,
        totalItems: totalItems,
        totalPages: totalPages,
      },
    };

    this.patientService.patientList.next(paginatedPatients);
    this.patients = new MatTableDataSource<Patient>(paginatedPatients);
  }

  handleRowClick(patient: Patient): void {
    this.router.navigate(['dashboard', 'patients', 'edit', patient.id]);
  }

  handlePage(pageEvent: PageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.applySearchAndPagination(pageEvent.pageIndex);
  }
}
