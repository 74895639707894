import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    return component.canDeactivate()
      ? true
      : confirm(
          'AVERTISSEMENT : Vous avez des modifications non enregistrées. Appuyez sur "Cancel" pour revenir en arrière et enregistrer ces modifications, ou sur OK pour les perdre.'
        );
  }
}
