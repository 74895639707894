<div class="sidebar" (mouseover)="over()" (mouseout)="out()">
  <mat-nav-list>
    <img
      src="../../../../assets/images/logo.png"
      #logo
      (click)="welcomePageHandler()"
      class="welcome-page-handler"
    />

    <a mat-list-item routerLink="patients" routerLinkActive="active">
      <mat-icon class="icon">
        people
      </mat-icon>
      Patients</a
    >
    <a mat-list-item routerLink="practitioners" routerLinkActive="active">
      <mat-icon class="icon">
        supervisor_account
      </mat-icon>
      Praticiens</a
    >
    <a mat-list-item routerLink="synthesis-orders" routerLinkActive="active">
      <mat-icon class="icon">
        assignment_turned_in
      </mat-icon>
      Demandes de synthèse</a
    >
    <a mat-list-item routerLink="exports" routerLinkActive="active">
      <mat-icon class="icon"> file_upload </mat-icon>Exports</a
    >
    <a
      mat-list-item
      routerLink="activation-key"
      routerLinkActive="active"
      (click)="activationKeyHandler()"
    >
      <mat-icon class="icon"> key </mat-icon>Clé d'activation
    </a>
    <!-- <a
      mat-list-item
      routerLink="user-dashboard"
      routerLinkActive="active"
      (click)="activationKeyHandler()"
    >
      <mat-icon class="icon"> dashboard </mat-icon>Tableau de bord utilisateur
    </a> -->
    <a
      mat-list-item
      routerLink="key-requests"
      routerLinkActive="active"
      (click)="activationKeyHandler()"
    >
      <mat-icon class="icon"> dashboard </mat-icon>
      Tableau de bord utilisateur</a
    >
    <!-- <a
      mat-list-item
      routerLink="automatic-emails"
      routerLinkActive="active"
      (click)="activationKeyHandler()"
    >
      <mat-icon class="icon"><b>commit</b> </mat-icon>User States
    </a> -->

    <a
      mat-list-item
      routerLink="company"
      routerLinkActive="active"
      (click)="companyHandler()"
    >
      <mat-icon class="icon">apartment </mat-icon>Company
    </a>

    <a mat-list-item routerLink="states" routerLinkActive="active">
      <mat-icon class="icon">analytics </mat-icon>States
    </a>
  </mat-nav-list>
</div>
