import { StartModel } from './start.model';
import { UserDashboardService } from './../../../services/user-dashboard.service';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss'],
})
export class StarComponent implements OnInit {
  @Input('star') star = 'star_outline';
  @Input('userId') userId: string;
  @Input('scoreId') scoreId: string;
  constructor(private userDashboardService: UserDashboardService) {}
  ngOnInit(): void {
    if (this.scoreId) {
      this.userDashboardService
        .getStartStatus(this.scoreId)
        .subscribe((reuslt: StartModel) => {
          if (reuslt) {
            if (reuslt.star) {
              this.star = 'star';
            } else {
              this.star = 'star_outline';
            }
          }
        });
    } else {
      this.userDashboardService
        .getStartStatusByUserId(this.userId)
        .subscribe((reuslt: StartModel) => {
          if (reuslt) {
            if (reuslt.star) {
              this.star = 'star';
            } else {
              this.star = 'star_outline';
            }
          }
        });
    }
  }
  changeStar() {
    if (this.star === 'star') {
      const startStateData = {
        user_id: this.userId,
        star: false,
        score_id: this.scoreId,
      };

      this.userDashboardService
        .setStarStatus(startStateData)
        .subscribe((data: any) => {
          if (data) {
            this.star = 'star_outline';
          }
        });
    } else {
      const startStateData = {
        user_id: this.userId,
        star: true,
        score_id: this.scoreId,
      };

      this.userDashboardService
        .setStarStatus(startStateData)
        .subscribe((data: any) => {
          if (data) {
            this.star = 'star';
          }
        });
    }
  }
}
