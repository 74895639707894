import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  async canActivate() {
    const isAuthenticated = await this.authService
      .isAuthenticated()
      .toPromise();
    if (!isAuthenticated) {
      return false;
    }
    return this.authService.checkIfNotPileje();
  }
}
