import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  private commercialUrl = environment.api.commercialUrl;
  campaignStorage = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}

  getCampaignByCompanyId(companyId: string) {
    return this.http.get(this.commercialUrl + '/campaign/' + companyId);
  }
  getSumActivationKeyByCompany(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/activationkeys/company/' + companyName
    );
  }

  getSumOfQuestionerSentByCompany(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/company/' + companyName
    );
  }

  getSumOfScoreViewedByCompany(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/score-viewed/' + companyName
    );
  }

  getSumOfIndexesViewedByCompany(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/indexes-viewed/' + companyName
    );
  }

  getSumOfRecosViewedByCompany(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/recos-viewed/' + companyName
    );
  }

  getSumOfVideoViewedByCompany(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/videos-viewed/' + companyName
    );
  }

  getSumOfBoughtPhytotherapyByCompany(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/bought-phytotherapies/' + companyName
    );
  }

  getSumOfUsersBuyingRDVExpertByCompany(
    companyName: string
  ): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/bought-rdv-expert/' + companyName
    );
  }

  getSumOfAnsweringSatisfactionSurveyByCompany(
    companyName: string
  ): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl +
        '/user-state/answering-satisfaction-survey/' +
        companyName
    );
  }
  getSumOfUsersAskingAKByCompany(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/users-asking-ak/' + companyName
    );
  }

  //usersAnsweringSatisfactionSurvey
}
