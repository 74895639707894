import { FormBuilder } from '@angular/forms';

import { StatesService } from '../states.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'edit-column.dialog',
  templateUrl: 'edit-column.dialog.html',
})
export class EditColumnDialog implements OnInit {
  newName: string;
  columnName: any;
  constructor(
    public dialogRef: MatDialogRef<EditColumnDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private statesService: StatesService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.columnName = this.data.columnName;
  }
  save() {
    let updatedValue = this.statesService.alliasList.getValue();
    updatedValue[this.columnName] = this.newName;
    this.statesService.saveAlias(updatedValue).subscribe((result) => {
      this.statesService.alliasList.next(updatedValue);
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  animal: string;
  columnName: string;
}
