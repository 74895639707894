import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Practitioner } from '../../../core/models/practitioner.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-praticioners-list',
  templateUrl: './praticioners-list.component.html',
  styleUrls: ['./praticioners-list.component.scss'],
})
export class PraticionersListComponent implements OnInit {
  @Input() public practitioners: Practitioner[];
  public showToolbar: boolean = false;
  public isPilejeRoute: boolean = false;
  public displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'rpps',
    'enabled',
  ];

  @Output() public rowClick = new EventEmitter<Practitioner>();

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((urlSegments) => {
      const url = urlSegments.map((segment) => segment.path).join('/');
      this.showToolbar = url === 'pileje/practitioners';
      this.isPilejeRoute = this.showToolbar;

      // Update displayedColumns based on route
      if (this.isPilejeRoute) {
        this.displayedColumns = [
          'name',
          'email',
          'phone',
          'rpps',
          'medicalDomain',
          'enabled',
        ];
      } else {
        this.displayedColumns = ['name', 'email', 'phone', 'rpps', 'enabled'];
      }
    });
  }

  handleRowClick(row: Practitioner): void {
    this.rowClick.emit(row);
  }

  getBadgeClass(enabled: boolean | null): string {
    if (enabled === true) {
      return 'primary';
    } else if (enabled === false) {
      return 'warn';
    } else {
      return 'pending';
    }
  }

  getBadgeText(enabled: boolean | null): string {
    if (enabled === true) {
      return 'Activé';
    } else if (enabled === false) {
      return 'Refusé';
    } else {
      return 'En attente';
    }
  }
}
