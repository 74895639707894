<mat-card>
  <mat-card-header>
    <mat-card-title>Campaign</mat-card-title>
    <button
      mat-raised-button
      color="primary"
      class="medium-margin"
      (click)="test()"
    >
      See users
    </button>
  </mat-card-header>

  <table mat-table [dataSource]="campaign" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>createdAt</th>
      <td mat-cell *matCellDef="let element">{{ element.createdAt | date }}</td>
    </ng-container>

    <ng-container matColumnDef="isDeleted">
      <th mat-header-cell *matHeaderCellDef>isDeleted</th>
      <td mat-cell *matCellDef="let element">{{ element.isDeleted }}</td>
    </ng-container>

    <ng-container matColumnDef="keyType">
      <th mat-header-cell *matHeaderCellDef>keyType</th>
      <td mat-cell *matCellDef="let element">{{ element.keyType }}</td>
    </ng-container>

    <ng-container matColumnDef="quota">
      <th mat-header-cell *matHeaderCellDef>quota</th>
      <td mat-cell *matCellDef="let element">{{ element.quota }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>type</th>
      <td mat-cell *matCellDef="let element">{{ element.type }}</td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef>updatedAt</th>
      <td mat-cell *matCellDef="let element">{{ element.updatedAt | date }}</td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsCampaign"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsCampaign"></tr>
  </table>
</mat-card>

<mat-card>
  <mat-card-title>Details</mat-card-title>
  <div>
    <table class="info-table">
      <tr>
        <td>Users Asking Activation Code</td>
        <td>{{ usersAskingActivationCode }}</td>
      </tr>

      <tr class="highlight">
        <td>Users Creating Account</td>
        <td>{{ usersCreatingAccount }}</td>
      </tr>
      <tr>
        <td>Users Filling Questionnaire</td>
        <td>{{ usersFillingQuestionnaire }}</td>
      </tr>
      <tr class="highlight">
        <td>Users Reading Scores</td>
        <td>{{ usersReadingScores }}</td>
      </tr>
      <tr>
        <td>Users Reading Index</td>
        <td>{{ usersReadingIndex }}</td>
      </tr>
      <tr class="highlight">
        <td>Users Reading Recommendations</td>
        <td>{{ usersReadingRecos }}</td>
      </tr>
      <tr>
        <td>Users Watching Videos</td>
        <td>{{ usersWatchingVideos }}</td>
      </tr>
      <tr class="highlight">
        <td>Users Buying Phytotherapy</td>
        <td>{{ usersBuyingPhytotherapy }}</td>
      </tr>
      <tr>
        <td>Users Buying RDV expert</td>
        <td>{{ usersBuyingRDVExpert }}</td>
      </tr>
      <!-- <tr class="tr-8">
        <td>Users Answering Satisfaction Survey</td>
        <td>{{ usersAnsweringSatisfactionSurvey }}</td>
      </tr> -->
    </table>
  </div>
</mat-card>
