<div class="error" *ngIf="error_message">
  <h2>An error has been occurred!</h2>
  <p>{{ error_message }}</p>
  <span class="cross" (click)="closeErrorBox()">X</span>
</div>
<div *ngIf="package_info">
  <fieldset>
    <legend>
      <h3>
        <b> {{ package_info.title }}</b>
      </h3>
    </legend>
    <h3>Full: {{ package_info.full }}</h3>
    <h3>Treatment: {{ package_info.treatment }}</h3>
    <h3>Consultation: {{ package_info.consultation }}</h3>
    <h3>Box: {{ package_info.box }}</h3>
  </fieldset>
</div>
<mat-horizontal-stepper linear>
  <!-- <mat-step label="Create Company" [completed]="companyValidated" #stepper -->
  <mat-step label="Créer une entreprise" [completed]="true" #stepper
    ><mat-form-field>
      <mat-label>ID de l'entreprise</mat-label>
      <input
        [(ngModel)]="company_id"
        matInput
        #companyID
        placeholder="PN012345"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label> Nom de l'entreprise</mat-label>
      <input
        [(ngModel)]="company_name"
        matInput
        #companyName
        placeholder="Numa Health International"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label> Domaine </mat-label>

      <input
        [(ngModel)]="company_domain"
        matInput
        #domain
        placeholder="^numadhealth\.com$"
      />
    </mat-form-field>

    <br />
    <fieldset>
      <legend>Astuce pour le domaine</legend>
      Exemple : le domaine "numadhealth.com" doit être écrit comme
      "^numadhealth\.com$"
    </fieldset>

    <div>
      <mat-spinner *ngIf="spinnerVisible"></mat-spinner>
      <img
        *ngIf="companyValidated"
        src="../../../../../assets/images/complete.png"
        class="complete-image"
      />
      <h2 class="complete-text" *ngIf="companyValidated">
        L'entreprise a été créée avec succès !
      </h2>
      <br />
      <button
        *ngIf="!companyValidated"
        (click)="
          createCompany(companyID.value, companyName.value, domain.value)
        "
        mat-raised-button
        color="primary"
        class="create-button"
      >
        Créer
      </button>
      <button
        [disabled]="!companyValidated"
        matStepperNext
        mat-raised-button
        color="primary"
        class="next-button"
        navigate_next
      >
        Next <mat-icon> navigate_next</mat-icon>
      </button>
    </div>
  </mat-step>
  <!-- <mat-step label="Create Campaign" [completed]="campaignValidated"> -->
  <mat-step label="Créer une campagne" [completed]="true">
    <mat-form-field>
      <mat-label> Nom de la campagne </mat-label>
      <input
        matInput
        #campaignName
        [(ngModel)]="campaign_name"
        placeholder="Numa Campaign"
      />
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-label> Type </mat-label>

      <input matInput #type [(ngModel)]="campaign_type" />
    </mat-form-field> -->

    <mat-form-field>
      <mat-label>Sélectionnez un type</mat-label>
      <mat-select #type [(ngModel)]="campaign_type" required="true">
        <mat-option value="fixed">Fixed</mat-option>
        <mat-option value="continuous">Continuous</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label> Quota </mat-label>

      <input
        matInput
        #quota
        [(ngModel)]="campaign_quota"
        required
        type="number"
        placeholder="9"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label> ID de l'entreprise </mat-label>

      <input
        matInput
        #campCompanyID
        [(ngModel)]="company_id"
        required
        placeholder="PN012345"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Type de clé </mat-label>

      <input
        matInput
        #keytype
        [(ngModel)]="campaign_key_type"
        required
        placeholder="pack"
      />
    </mat-form-field>

    <br />
    <div>
      <mat-spinner *ngIf="spinnerVisible"></mat-spinner>
      <img
        *ngIf="campaignValidated"
        src="../../../../../assets/images/complete.png"
        class="complete-image"
      />
      <h2 class="complete-text" *ngIf="campaignValidated">
        The campaign was created successfully!
      </h2>
      <h2 class="form-validation" *ngIf="formValidator">
        Please fill the form inputs properly.
      </h2>
      <br />
      <button
        *ngIf="!campaignValidated"
        (click)="
          createCampaign(campaignName, type, quota, campCompanyID, keytype)
        "
        mat-raised-button
        color="primary"
        class="create-button"
      >
        Créer
      </button>
      <button
        [disabled]="!campaignValidated"
        matStepperNext
        mat-raised-button
        color="primary"
        class="next-button"
        navigate_next
      >
        Next <mat-icon> navigate_next</mat-icon>
      </button>
    </div></mat-step
  >
  <mat-step label="Clés d'activation" [completed]="true">
    <mat-form-field>
      <mat-label> Type de produit </mat-label>
      <input
        matInput
        #productType
        [(ngModel)]="campaign_key_type"
        required
        placeholder="pack"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label> Quantité </mat-label>

      <input
        matInput
        #quantity
        [(ngModel)]="campaign_quota"
        required
        placeholder="9"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>ID de l'entreprise</mat-label>

      <input
        matInput
        #companyId
        [(ngModel)]="company_id"
        required
        placeholder="PN012345"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label> Adresse mail </mat-label>

      <input
        matInput
        #mailAddress
        [(ngModel)]="mail_address"
        placeholder="alim@numahealth.com"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label> Type de campagne </mat-label>

      <input
        matInput
        #campaignType
        [(ngModel)]="campaign_type"
        required
        placeholder="Fixed"
      />
    </mat-form-field>
    <br />
    <div>
      <mat-spinner *ngIf="spinnerVisible"></mat-spinner>
      <img
        *ngIf="activationKeyValidated"
        src="../../../../../assets/images/complete.png"
        class="complete-image"
      />
      <h2 class="complete-text__activationkey" *ngIf="activationKeyValidated">
        Les clés demandées ont été générées avec succès !
      </h2>

      <br />
      <div class="button-container">
        <h2 class="form-validation" *ngIf="formValidator">
          Veuillez remplir correctement les entrées du formulaire.
        </h2>
        <br />
        <button
          *ngIf="!activationKeyValidated"
          (click)="
            finalizeRequest(
              productType,
              quantity,
              companyId,
              mailAddress,
              campaignType
            )
          "
          mat-raised-button
          color="primary"
          class="generate-keys"
        >
          Générer des clés
        </button>
        <button
          *ngIf="!activationKeyValidated"
          (click)="finishRequest()"
          mat-raised-button
          color="primary"
          class="finish"
        >
          Finir
        </button>
      </div>
      <img
        *ngIf="requestFinished"
        src="../../../../../assets/images/complete.png"
        class="finish-image"
      />
      <h2 class="complete-text__finish" *ngIf="requestFinished">
        Les paramètres de la campagne et de l'entreprise ont déjà été soumis,
        vous pouvez maintenant générer des clés d'activation ici ou en utilisant
        le portail.
      </h2>
    </div>
    <app-order-result *ngIf="activationKeyValidated"></app-order-result>
  </mat-step>
</mat-horizontal-stepper>
