<app-toolbar *ngIf="showToolbar"></app-toolbar>
<div class="edit-practitioner-container">
  <ng-container *ngIf="loaded">
    <div class="box">
      <app-practitioner-switch-status
        [practitioner]="practitioner"
        (switch)="handleSuccess($event)"
      ></app-practitioner-switch-status>
      <app-edit-practitioner-form
        [practitioner]="practitioner"
        (succeed)="handleSuccess($event)"
      ></app-edit-practitioner-form>
    </div>
    <div class="box" *ngIf="!isPilejeRoute">
      <app-credits-practitioner-form
        [practitionerCredit]="practitionerCredit"
        (succeed)="handleSuccess($event)"
        (submitError)="handleError($event)"
      ></app-credits-practitioner-form>
    </div>
  </ng-container>
</div>
