<mat-form-field appearance="standard">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
</mat-form-field>

<mat-button-toggle-group name="modes" aria-label="modes" value="setup">
  <mat-button-toggle value="setup" (click)="setupHandler()"
    >Onboarding</mat-button-toggle
  >
  <mat-button-toggle value="evaluation" (click)="evaluationHandler()"
    >Pre-analysis</mat-button-toggle
  >
  <mat-button-toggle value="action" (click)="actionHandler()"
    >Post-Analysis</mat-button-toggle
  >
  <mat-button-toggle value="states" (click)="statesHandler()"
    >Stats</mat-button-toggle
  >
</mat-button-toggle-group>
<mat-button-toggle-group
  name="modes"
  aria-label="modes"
  value="dates"
  class="view-mode"
>
  <mat-button-toggle value="dates" (click)="viewModeHandler()"
    >Dates</mat-button-toggle
  >
  <mat-button-toggle value="checks" (click)="checkModeHandler()"
    >Checks</mat-button-toggle
  >
</mat-button-toggle-group>

<br />
<div class="set-up" *ngIf="setup">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FirstName</th>
        <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>LastName</th>
        <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="first_visit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FirstVisit</th>
        <td mat-cell *matCellDef="let row">
          <!-- {{ row.first_visit ? (!viewMode ? row.first_visit : 'N/A') : ' N/A' }} -->
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.first_visit ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.first_visit ? (row.first_visit | date) : 'N/A' }}</span
          >
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="last_visit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>LastVisit</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.last_visit ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode"
            >{{ row.last_visit ? (row.last_visit | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="activation_key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ActivationKey</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.activation_key ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.activation_key ? (row.activation_key | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="questionnaire_sent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          QuestionerSent
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.questionnaire_sent ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{
              row.questionnaire_sent ? (row.questionnaire_sent | date) : 'N/A'
            }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="score_generated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          ScoreGenerated
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.score_generated ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>

          <span *ngIf="!viewMode">
            {{ row.score_generated ? (row.score_generated | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsSetup"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsSetup"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page of users"
    ></mat-paginator>
  </div>
</div>
<div class="evaluation" *ngIf="evaluation">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FirstName/th></th>
        <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>LastName</th>
        <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="last_visit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          LastVisit
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.last_visit ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.last_visit ? (row.last_visit | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="questionnaire_sent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          QuestionerSent
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.questionnaire_sent ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{
              row.questionnaire_sent ? (row.questionnaire_sent | date) : 'N/A'
            }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="questionnaire_domains_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>QuestionerNB</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.questionnaire_domains_number ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{
              row.questionnaire_domains_number
                ? (row.questionnaire_domains_number | date)
                : 'N/A'
            }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="data_stress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>data_stress</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.data_stress ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.data_stress ? (row.data_stress | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="data_clinical">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>data_clinical</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.data_clinical ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.data_clinical ? (row.data_clinical | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="data_analysis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>data_analysis</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.data_analysis ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.data_analysis ? (row.data_analysis | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="score_generated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          score_generated
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.score_generated ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.score_generated ? (row.score_generated | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="score_viewed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>score_viewed</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.score_viewed ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.score_viewed ? (row.score_viewed | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsEvaluation"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumnsEvaluation"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page of users"
    ></mat-paginator>
  </div>
</div>
<div class="action" *ngIf="action">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FirstName/th></th>
        <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>LastName</th>
        <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="last_visit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          LastVisit
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.last_visit ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.last_visit ? (row.last_visit | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="score_viewed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          score_viewed
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.score_viewed ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.score_viewed ? (row.score_viewed | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="video_viewed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>video_viewed</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.video_viewed ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.video_viewed ? (row.video_viewed | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="pdf_downloaded">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          pdf_downloaded
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.pdf_downloaded ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.pdf_downloaded ? row.pdf_downloaded : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="phyto_clicked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>phyto_clicked</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.phyto_clicked ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.phyto_clicked ? (row.phyto_clicked | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="indexes_viewed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          indexes_viewed
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.indexes_viewed ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.indexes_viewed ? (row.indexes_viewed | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="reco_viewed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          reco_viewed
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.reco_viewed ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.reco_viewed ? (row.reco_viewed | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumnsAction"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsAction"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page of users"
    ></mat-paginator>
  </div>
</div>
<div class="states" *ngIf="states">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FirstName/th></th>
        <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>LastName</th>
        <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="last_visit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          LastVisit
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.last_visit ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.last_visit ? (row.last_visit | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="score_generated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          score_generated
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.score_generated ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.score_generated ? (row.score_generated | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="questionnaire_domains_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          questionnaire_domains_number
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.questionnaire_domains_number ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{
              row.questionnaire_domains_number
                ? (row.questionnaire_domains_number | date)
                : 'N/A'
            }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="appointment_set">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          appointment_set
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.appointment_set ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.appointment_set ? (row.appointment_set | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="phyto_ordered">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>phyto_ordered</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.phyto_ordered ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.phyto_ordered ? (row.phyto_ordered | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="reco_checked_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          reco_checked_number
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.reco_checked_number ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{
              row.reco_checked_number ? (row.reco_checked_number | date) : 'N/A'
            }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="reco_commited">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          reco_commited
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.reco_commited ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.reco_commited ? (row.reco_commited | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="reco_notified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          reco_notified
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            class="material-icons"
            *ngIf="viewMode"
            class="{{ row.reco_notified ? 'green' : 'gray' }}"
          >
            fiber_manual_record
          </span>
          <span *ngIf="!viewMode">
            {{ row.reco_notified ? (row.reco_notified | date) : 'N/A' }}
          </span>
        </td>
      </ng-container>

      <!-- Fruit Column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumnsStates"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsStates"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page of users"
    ></mat-paginator>
  </div>
</div>
