export let defaultAlias = {
  user_id: '',
  company: '',
  date_of_birth: '',
  plan_start_date: '',
  plan_expiration_date: '',
  plan_cancelled_date: '',
  plan_type: '',
  plan_price: '',
  plan_sponsor: '',
  plan_isActive: '',
  first_visit: '',
  last_visit: '',
  last_bordereau_downloaded: '',
  appointment_set: '',
  data_analysis: '',
  data_clinical: '',
  data_stress: '',
  indexes_viewed: '',
  pdf_downloaded: '',
  phyto_viewed: '',
  phyto_ordered: '',
  reco_checked: '',
  reco_checked_number: '',
  reco_commited: '',
  reco_notified: '',
  reco_viewed: '',
  score_generated: '',
  score_id: '',
  score_viewed: '',
  score_state_createdat: '',
  questioner_domains_numbers: '',
  questioner_sent: '',
  questioner_id: '',
  questioner_state_createdat: '',
  email: '',
  campaign_id: '',
  company_id: '',
  company_name: '',
  activation_key: '',
  key_type: '',
  bilan_type: '',
  location_site: '',
  off_site: '',
  expert_call_date: '',
  expert: '',
  survey1_date: '',
  survey2_date: '',
  survey_rate: '',
  collection_date: '',
  note: '',
  score_entries_count: '',
};
