import { StatesComponent } from './pages/states/states.component';
import { CampaignDetailsComponent } from './pages/campaign-details/campaign-details.component';
import { CampaignComponent } from './pages/campaign/campaign.component';
import { CompanyComponent } from './pages/company/company.component';
import { UserStateComponent } from './pages/user-state/user-state.component';
import { KeyRequestsComponent } from './pages/key-requests/key-requests.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';

import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { StepperComponent } from './pages/activation-key/stepper/stepper.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { PatientsComponent } from './pages/patients/patients.component';
import { PractitionersComponent } from './pages/practitioners/practitioners.component';
import { EditPractitionerComponent } from './pages/edit-practitioner/edit-practitioner.component';
import { EditPatientComponent } from './pages/edit-patient/edit-patient.component';
import { SynthesisOrdersComponent } from './pages/synthesis-orders/synthesis-orders.component';
import { ShowSynthesisOrderComponent } from './pages/show-synthesis-order/show-synthesis-order.component';
import { ExportsComponent } from './pages/exports/exports.component';
import { ExportsExalisComponent } from './pages/exports/exports-exalis.component';
import { ActivationKeyComponent } from './pages/activation-key/activation-key.component';
import { PendingChangesGuard } from './pages/states/models/guards/pending-changes.guard';
import { PilejeAuthGuard } from '../auth/services/pileje-auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'exports',
        component: ExportsComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'exports-exalis',
        component: ExportsExalisComponent,
        canActivate: [PilejeAuthGuard], // Only PilejeAuthGuard applied here
      },
      {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'patients',
        component: PatientsComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'practitioners',
        component: PractitionersComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'synthesis-orders',
        component: SynthesisOrdersComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'company',
        component: CompanyComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'states',
        component: StatesComponent,
        canDeactivate: [PendingChangesGuard], // Deactivate guard stays the same
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'campaign',
        component: CampaignComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'campaign/:companyId',
        component: CampaignComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'campaign-details/:campaignId',
        component: CampaignDetailsComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'practitioners/edit/:practitionerId',
        component: EditPractitionerComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'patients/edit/:patientId',
        component: EditPatientComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'activation-key',
        component: ActivationKeyComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'user-dashboard',
        component: UserDashboardComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'automatic-emails',
        component: UserStateComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'key-requests',
        component: KeyRequestsComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'stepper',
        component: StepperComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
      {
        path: 'synthesis-orders/show/:synthesisId',
        component: ShowSynthesisOrderComponent,
        canActivate: [AuthGuard], // AuthGuard applied here
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
