import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CampaignDetailsService {
  companyStorage = new BehaviorSubject<any>(null);
  private commercialUrl = environment.api.commercialUrl;
  constructor(private http: HttpClient) {}

  countKeyRequestByCampaignId(campaignId: string) {
    return this.http.get(
      this.commercialUrl +
        '/user-state/users-asking-ak-by-campaign/' +
        campaignId
    );
  }

  getSumActivationKeyByCampaign(campaignId: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl +
        '/user-state/users-creating-account-by-campaign/' +
        campaignId
    );
  }
  //old
  // getSumActivationKeyByCampaign(campaignId: string): Observable<number> {
  //   return this.http.get<number>(
  //     this.commercialUrl +
  //       '/user-state/users-asking-ak-by-campaign/' +
  //       campaignId
  //   );
  // }

  getSumOfQuestionerSentByCampaign(campaignId: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/questioner-by-campaign/' + campaignId
    );
  }

  getSumOfScoreViewedByCampaign(campaignId: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/score-viewed-by-campaign/' + campaignId
    );
  }

  getSumOfIndexesViewedByCampaign(campaignId: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl +
        '/user-state/indexes-viewed-by-campaign/' +
        campaignId
    );
  }

  getSumOfRecosViewedByCampaign(campaignId: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/recos-viewed-by-campaign/' + campaignId
    );
  }

  getSumOfVideoViewedByCampaign(campaignId: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/videos-viewed-by-campaign/' + campaignId
    );
  }

  getSumOfBoughtPhytotherapyByCampaign(campaignId: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl +
        '/user-state/bought-phytotherapies-by-campaign/' +
        campaignId
    );
  }

  getSumOfUsersBuyingRDVExpertByCampaign(
    campaignId: string
  ): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl +
        '/user-state/bought-rdv-expert-by-campaign/' +
        campaignId
    );
  }

  getSumOfAnsweringSatisfactionSurveyByCampaign(
    companyName: string
  ): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl +
        '/user-state/answering-satisfaction-survey/' +
        companyName
    );
  }
  getSumOfUsersAskingAKByCampaign(companyName: string): Observable<number> {
    return this.http.get<number>(
      this.commercialUrl + '/user-state/users-asking-ak/' + companyName
    );
  }
}
