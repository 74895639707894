import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KeyRequestService {
  private commercialURL = environment.api.commercialUrl;
  private authURL = environment.api.authUrl;
  keyRequestData = [];
  completeData = [];
  constructor(private http: HttpClient) {}

  getAllKeyRequests() {
    return this.http.get(this.authURL + '/patients/key-requests');
  }
}
