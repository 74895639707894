<div class="login-container">
  <h1>Connexion</h1>
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <app-input label="Identifiant" formControlName="username"></app-input>
    <app-input label="Mot de passe" formControlName="password" type="password"></app-input>
    <button mat-raised-button color="primary" type="submit">Se connecter</button>
  </form>

  <div class="error" *ngIf="error">{{ error }}</div>
</div>
