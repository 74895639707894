import { UserDashboardService } from '../../services/user-dashboard.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';

export class userStateModel {
  first_name: string;
  last_name: string;
  user_id: string;
  appointment_set: Date;
  first_visit: Date;
  last_visit: Date;
  activation_key: Date;
  bordereau_downloaded: Date;
  questionnaire_sent: Date;
  questionnaire_domains_number: Date;
  data_stress: Date;
  data_clinical: Date;
  data_analysis: Date;
  score_generated: Date;
  score_viewed: Date;
  video_viewed: Date;
  pdf_downloaded: Date;
  phyto_clicked: Date;
  phyto_ordered: Date;
  indexes_viewed: Date;
  reco_viewed: Date;
  reco_clicked: Date;
  reco_checked: Date;
  reco_checked_number: Date;
  reco_commited: Date;
  reco_notified: Date;
}

@Component({
  selector: 'app-user-state',
  templateUrl: './user-state.component.html',
  styleUrls: ['./user-state.component.scss'],
})
export class UserStateComponent implements OnInit {
  @Output() setupEventHandller = new EventEmitter();

  displayedColumns: string[] = [
    'first_name',
    'last_name',
    'appointment_set',
    'first_visit',
    'last_visit',
    'activation_key',
    'bordereau_downloaded',
    'questionnaire_sent',
    'questionnaire_domains_number',
    'data_stress',
    'data_clinical',
    'data_analysis',
    'score_generated',
    'score_viewed',
    'video_viewed',
    'pdf_downloaded',
    'phyto_clicked',
    'phyto_ordered',
    'indexes_viewed',
    'reco_viewed',
    'reco_clicked',
    'reco_checked',
    'reco_checked_number',
    'reco_commited',
    'reco_notified',
    'company_id',
    'company_name',
  ];

  displayedColumnsSetup: string[] = [
    'first_name',
    'last_name',
    'first_visit',
    'last_visit',
    'activation_key',
    'questionnaire_sent',
    'score_generated',
  ];
  displayedColumnsEvaluation: string[] = [
    'first_name',
    'last_name',
    'last_visit',
    'questionnaire_sent',
    'questionnaire_domains_number',
    'data_stress',
    'data_clinical',
    'data_analysis',
    'score_generated',
    'score_viewed',
  ];
  displayedColumnsAction: string[] = [
    'first_name',
    'last_name',
    'last_visit',
    'score_viewed',
    'video_viewed',
    'pdf_downloaded',
    'phyto_clicked',
    'indexes_viewed',
    'reco_viewed',
  ];

  displayedColumnsStates: string[] = [
    'first_name',
    'last_name',
    'last_visit',
    'score_generated',
    'questionnaire_domains_number',
    'appointment_set',
    'phyto_ordered',
    'reco_checked_number',
    'reco_commited',
    'reco_notified',
  ];

  states = false;
  action = false;
  evaluation = false;
  setup = true;
  viewMode = false;
  dataSource: MatTableDataSource<userStateModel>;
  @Output() greetEvent = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userStateService: UserDashboardService) {}

  ngOnInit(): void {
    this.userStateService
      .getAllState()
      .subscribe((result: userStateModel[]) => {
        this.userStateService.userStates = result;
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
  ngAfterViewInit() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setupHandler() {
    this.setup = !this.setup;
    this.evaluation = false;
    this.action = false;
    this.states = false;
    this.setupEventHandller.emit();
  }
  evaluationHandler() {
    this.evaluation = !this.evaluation;
    this.action = false;
    this.states = false;
    this.setup = false;
  }
  actionHandler() {
    this.action = !this.action;
    this.states = false;
    this.evaluation = false;
    this.setup = false;
  }
  statesHandler() {
    this.states = !this.states;
    this.action = false;
    this.evaluation = false;
    this.setup = false;
  }
  viewModeHandler() {
    this.viewMode = false;
  }

  checkModeHandler() {
    this.viewMode = true;
  }

  dotView() {
    return '<h1>aa</h1>';
  }
}
