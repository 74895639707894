import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private commercialUrl = environment.api.commercialUrl;
  companyStorage = new BehaviorSubject<any>(null);
  searchStorage = new BehaviorSubject<string>(null);
  constructor(private http: HttpClient) {}

  getCompanies() {
    return this.http.get(this.commercialUrl + '/companies');
  }
}
