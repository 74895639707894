import { CampaignDetailsService } from './campaign-details.service';
import { CampaignService } from './../campaign/campaign.service';
import { CompanyService } from './../company/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignModel } from './../campaign/campaign.model';
import { CompanyModel } from './../company/company.model';
import { Component, OnInit } from '@angular/core';
import { SearchGlobalService } from 'src/app/core/services/search-global.service';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss'],
})
export class CampaignDetailsComponent implements OnInit {
  campaign: CampaignModel[];
  // campaign: CampaignModel[];

  displayedColumns: string[] = ['id', 'name', 'domain'];
  displayedColumnsCampaign: string[] = [
    'id',
    'name',
    'quota',
    'isDeleted',
    'type',
    'updatedAt',
    'createdAt',
    'keyType',
  ];

  usersCreatingAccount: number;
  usersFillingQuestionnaire: number;
  usersFillingBloodTestsResults: number;
  usersReadingScores: number;
  usersReadingIndex: number;
  usersReadingRecos: number;
  usersWatchingVideos: number;
  usersBuyingPhytotherapy: number;
  usersBuyingRDVExpert: number;
  usersAnsweringSatisfactionSurvey: number;
  usersAskingActivationCode: number;
  constructor(
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private campaignService: CampaignService,
    private campaignDetailsService: CampaignDetailsService,
    private globalSearchService: SearchGlobalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((campaignIdObject) => {
      this.campaignService.campaignStorage.subscribe(
        (campaigns: CampaignModel[]) => {
          if (campaigns) {
            let filteredCampaign = campaigns.filter(
              (campaign) => campaign.id === campaignIdObject.campaignId
            );
            this.campaign = filteredCampaign;
            this.campaignDetailsService
              .countKeyRequestByCampaignId(campaignIdObject.campaignId)
              .subscribe((result: number) => {
                this.usersAskingActivationCode = result;
              });

            this.campaignDetailsService
              .getSumActivationKeyByCampaign(filteredCampaign[0].id)
              .subscribe((result: number) => {
                this.usersCreatingAccount = result;
              });

            this.campaignDetailsService
              .getSumOfQuestionerSentByCampaign(filteredCampaign[0].id)
              .subscribe((result: number) => {
                this.usersFillingQuestionnaire = result;
              });

            this.campaignDetailsService
              .getSumOfScoreViewedByCampaign(filteredCampaign[0].id)
              .subscribe((result: number) => {
                this.usersReadingScores = result;
              });

            this.campaignDetailsService
              .getSumOfIndexesViewedByCampaign(filteredCampaign[0].id)
              .subscribe((result: number) => {
                this.usersReadingIndex = result;
              });

            this.campaignDetailsService
              .getSumOfRecosViewedByCampaign(filteredCampaign[0].id)
              .subscribe((result: number) => {
                this.usersReadingRecos = result;
              });

            this.campaignDetailsService
              .getSumOfVideoViewedByCampaign(filteredCampaign[0].id)
              .subscribe((result: number) => {
                this.usersWatchingVideos = result;
              });

            this.campaignDetailsService
              .getSumOfBoughtPhytotherapyByCampaign(filteredCampaign[0].id)
              .subscribe((result: number) => {
                this.usersBuyingPhytotherapy = result;
              });

            this.campaignDetailsService
              .getSumOfUsersBuyingRDVExpertByCampaign(filteredCampaign[0].id)
              .subscribe((result: number) => {
                this.usersBuyingRDVExpert = result;
              });
            this.campaignDetailsService
              .getSumOfUsersAskingAKByCampaign(filteredCampaign[0].name)
              .subscribe((result: number) => {
                this.usersAskingActivationCode = result;
              });
          }
        },
        (error) => {
          console.log('An error occured!');
        }
      );
    });
  }
  test() {
    this.globalSearchService.globalSearchStorage.next(this.campaign[0].id);
    this.router.navigate(['/states']);
  }
  onShowDetials(input: any) {}
}
