<table mat-table [dataSource]="dataSource" class="mat-elevation-z4" matSort>
  <!-- First Name Column (FirstName + LastName) -->
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Prénom</th>
    <td mat-cell *matCellDef="let patient">
      {{ patient.firstName }} 
    </td>
  </ng-container>

    <!-- Last Name Column (FirstName + LastName) -->
  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
    <td mat-cell *matCellDef="let patient">
      {{ patient.lastName }}
    </td>
  </ng-container>


  <!-- Gender Column -->
  <ng-container matColumnDef="gender">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Genre</th>
    <td mat-cell *matCellDef="let patient">
      {{ patient.gender === 'F' ? 'Femme' : 'Homme' }}
    </td>
  </ng-container>
  <!-- E-mail Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</th>
    <td mat-cell *matCellDef="let patient">{{ patient.email }}</td>
  </ng-container>

  <!-- Identifiant Column -->
  <ng-container matColumnDef="socialNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Identifiant</th>
    <td mat-cell *matCellDef="let patient">{{ patient.socialNumber }}</td>
  </ng-container>


    <!-- Active At -->

  <ng-container matColumnDef="enabled">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Actif / Inactif</th>
    <td mat-cell *matCellDef="let patient" >
      <span class="badge" [class]="patient.enabled ? 'primary' : 'warn'">{{
        patient.enabled ? 'Actif' : 'Inactif'
      }}</span>
    </td>
  </ng-container>

    <!-- Created At -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CreatedAt</th>
      <td mat-cell *matCellDef="let patient">{{ patient.createdDate | date }}</td>
    </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    class="patient-row"
    *matRowDef="let row; columns: displayedColumns"
    (click)="handleRowClick(row)"
  ></tr>
</table>
