<h1 mat-dialog-title>Edit Column</h1>
<div mat-dialog-content>
  <p>Real Name: {{data.columnName}}</p>
  <p>Alias: <input type="text" [(ngModel)]="newName" /></p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="data.animal"
    cdkFocusInitial
    (click)="save()"
  >
    Save
  </button>
</div>
