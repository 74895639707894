<div
  class="practitioner-form-container"
  [ngClass]="{ 'pileje-margin': isPilejeRoute }"
>
  <form [formGroup]="practitionerForm" (ngSubmit)="handleSubmit()">
    <ng-container *ngIf="isPilejeRoute; else normalForm">
      <!-- Accordion for Pileje Route -->
      <button mat-icon-button [routerLink]="['/pileje/practitioners']">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <mat-accordion [multi]="true">
        <!-- Information and Address Section -->
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>Informations et Adresse</mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Informations -->
          <section>
            <div class="input-row">
              <app-input
                formControlName="firstName"
                label="Prénom"
                [value]="getFormValue('firstName')"
              ></app-input>
              <app-input
                formControlName="lastName"
                label="Nom"
                [value]="getFormValue('lastName')"
              ></app-input>
              <app-input
                formControlName="identificationNumber"
                label="N° RPPS"
                [value]="getFormValue('identificationNumber')"
              ></app-input>
            </div>
            <div class="input-row">
              <app-input
                formControlName="email"
                label="E-mail"
                [value]="getFormValue('email')"
              ></app-input>
              <app-input
                formControlName="phoneNumber"
                label="Téléphone"
                [value]="getFormValue('phoneNumber')"
              ></app-input>
            </div>
            <div class="input-row">
              <app-input
                formControlName="codeTiersCompte"
                label="Code Tiers Compte"
                [value]="getFormValue('codeTiersCompte')"
              ></app-input>
              <app-input
                formControlName="codeContact"
                label="Code Contact"
                [value]="getFormValue('codeContact')"
              ></app-input>
              <app-input
                formControlName="medicalDomain"
                label="Spécialité"
                [value]="getFormValue('medicalDomain')"
              ></app-input>
            </div>
          </section>

          <!-- Adresse -->
          <section>
            <div class="input-row">
              <app-input
                formControlName="address"
                label="Adresse"
                [value]="getFormValue('address')"
              ></app-input>
              <app-input
                formControlName="zipCode"
                label="Code postal"
                [value]="getFormValue('zipCode')"
              ></app-input>
            </div>
            <div class="input-row">
              <app-input
                formControlName="city"
                label="Ville"
                [value]="getFormValue('city')"
              ></app-input>
              <app-input
                formControlName="country"
                label="Pays"
                [value]="getFormValue('country')"
              ></app-input>
            </div>
          </section>
        </mat-expansion-panel>

        <!-- Changer Mot de Passe Section -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Changer Mot de Passe</mat-panel-title>
          </mat-expansion-panel-header>

          <div class="input-row" formGroupName="passwords">
            <app-input
              formControlName="password"
              label="Nouveau mot de passe"
              type="password"
            ></app-input>
            <app-input
              formControlName="confirmationPassword"
              label="Confirmer mot de passe"
              type="password"
            ></app-input>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <!-- Default Form (when not Pileje Route) -->
    <ng-template #normalForm>
      <section>
        <h2>Informations</h2>
        <div class="input-row">
          <app-input
            formControlName="firstName"
            label="Prénom"
            [value]="getFormValue('firstName')"
          ></app-input>
          <app-input
            formControlName="lastName"
            label="Nom"
            [value]="getFormValue('lastName')"
          ></app-input>
          <app-input
            formControlName="identificationNumber"
            label="N° RPPS"
            [value]="getFormValue('identificationNumber')"
          ></app-input>
        </div>
        <div class="input-row">
          <app-input
            formControlName="email"
            label="E-mail"
            [value]="getFormValue('email')"
          ></app-input>
          <app-input
            formControlName="phoneNumber"
            label="Téléphone"
            [value]="getFormValue('phoneNumber')"
          ></app-input>
        </div>
      </section>
      <section>
        <h2>Adresse</h2>
        <div class="input-row">
          <app-input
            formControlName="address"
            label="Adresse"
            [value]="getFormValue('address')"
          ></app-input>
          <app-input
            formControlName="zipCode"
            label="Code postal"
            [value]="getFormValue('zipCode')"
          ></app-input>
        </div>
        <div class="input-row">
          <app-input
            formControlName="city"
            label="Ville"
            [value]="getFormValue('city')"
          ></app-input>
          <app-input
            formControlName="country"
            label="Pays"
            [value]="getFormValue('country')"
          ></app-input>
        </div>
      </section>
      <section>
        <h2>Changer mot de passe</h2>
        <div class="input-row" formGroupName="passwords">
          <app-input
            formControlName="password"
            label="Nouveau mot de passe"
            type="password"
          ></app-input>
          <app-input
            formControlName="confirmationPassword"
            label="Confirmer mot de passe"
            type="password"
          ></app-input>
        </div>
      </section>
    </ng-template>

    <button
      [ngClass]="{ 'pileje-margin': isPilejeRoute }"
      mat-raised-button
      color="primary"
      type="submit"
    >
      Enregistrer
    </button>
  </form>
</div>
