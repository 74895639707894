import { RequestDataType } from './../models/request-data-type';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivationKeyService } from '../../../services/activation-key.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input('title') title: string;
  @Input('subtitle') subtitle: string;
  @Input('content_object_full') full: number;
  @Input('content_object_treatment') treatment: number;
  @Input('content_object_consultation') consultation: number;
  @Input('content_object_box') box: number;
  @Input('type') type: string;

  constructor(
    public dialog: MatDialog,
    private activationKeyService: ActivationKeyService,
    private activeRouter: ActivatedRoute,
    private router: Router
  ) {}

  openDialog(): void {
    this.activationKeyService.package_data.next({
      title: this.title,
      full: this.full,
      treatment: this.treatment,
      consultation: this.consultation,
      box: this.box,
      type: this.type,
    });
    // const dialogRef = this.dialog.open(DialogContentExampleDialog);
    // dialogRef.afterClosed().subscribe((result) => {});
    this.router.navigate(['stepper']);
  }

  ngOnInit(): void {}
}
@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
  styleUrls: ['dialog-content-example-dialog.scss'],
})
export class DialogContentExampleDialog implements OnInit {
  package_data_sub: Subscription;
  package_info: any;
  counter = 1;

  // name: string;
  // type: string;
  // full: number;
  // treatment: number;
  // consultation: number;
  // box: number;
  constructor(private activationKeyService: ActivationKeyService) {}

  ngOnInit(): void {
    this.package_data_sub = this.activationKeyService.package_data.subscribe(
      (data) => {
        this.package_info = data;
      }
    );
  }
  onIncrease() {
    this.counter++;
  }
  onDecrease() {
    if (this.counter > 0) this.counter--;
  }
  generate(
    campaignType: string,
    quantity: number,
    packageType: string,
    companyName: string
  ) {
    let package_temp_data: RequestDataType = {
      type: packageType,
      quantity: quantity,
      campaignType: campaignType,

      companyId: companyName,
    };

    this.activationKeyService
      .generatePackage(package_temp_data)
      .subscribe((data) => {
        console.log(data);
        this.activationKeyService.ordered_package.next(data);
      });
  }
}
