import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Practitioner,
  UpdatePractitionerDto,
} from '../../../core/models/practitioner.model';
import { BehaviorSubject } from 'rxjs';
import { pairwise, takeWhile } from 'rxjs/operators';
import { PasswordValidator } from '../../../core/validators/password.validator';
import { PractitionerService } from '../../services/practitioner.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-practitioner-form',
  templateUrl: './edit-practitioner-form.component.html',
  styleUrls: ['./edit-practitioner-form.component.scss'],
})
export class EditPractitionerFormComponent implements OnInit {
  public $practitioner = new BehaviorSubject<Practitioner>(null);
  @Input()
  set practitioner(value) {
    this.$practitioner.next(value);
  }
  get practitioner(): Practitioner {
    return this.$practitioner.getValue();
  }

  @Output() succeed = new EventEmitter<string>();
  public practitionerForm: FormGroup;
  public isPilejeRoute: boolean = false;
  private practitionerId: string;
  private readonly practitionerUrl = environment.api.authUrl + '/practitioners';

  constructor(
    private practitionerService: PractitionerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.setFormValues();
    this.setPasswordValidators();

    this.route.paramMap.subscribe((params) => {
      this.practitionerId = params.get('practitionerId');
      const url = this.route.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      this.isPilejeRoute = url.includes(
        `pileje/practitioners/edit/${this.practitionerId}`
      );
    });
  }

  initForm(): void {
    this.practitionerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      identificationNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required]),
      codeTiersCompte: new FormControl(''),
      codeContact: new FormControl(''),
      medicalDomain: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      passwords: new FormGroup({
        password: new FormControl(''),
        confirmationPassword: new FormControl(''),
      }),
    });
  }

  setFormValues(): void {
    this.$practitioner
      .pipe(
        takeWhile(
          (practitioner) => practitioner === undefined || practitioner === null,
          true
        )
      )
      .subscribe((practitioner) => {
        if (practitioner) {
          this.practitionerForm.patchValue({
            firstName: practitioner.firstName,
            lastName: practitioner.lastName,
            identificationNumber: practitioner.identificationNumber,
            email: practitioner.email.toLowerCase(),
            phoneNumber: practitioner.phoneNumber,
            codeTiersCompte: practitioner.codeTiersCompte,
            codeContact: practitioner.codeContact,
            medicalDomain: practitioner.medicalDomain,
            address: practitioner.address,
            zipCode: practitioner.zipCode,
            city: practitioner.city,
            country: practitioner.country,
          });
        }
      });
  }

  setPasswordValidators(): void {
    const passwordsGroup: FormGroup = this.practitionerForm.get(
      'passwords'
    ) as FormGroup;

    PasswordValidator.setValidators(
      passwordsGroup,
      'password',
      'confirmationPassword',
      false
    ).subscribe();
  }

  getFormValue(name: string): string {
    const control = this.practitionerForm.get(name);
    return control ? control.value : '';
  }

  handleSubmit(): void {
    if (this.practitionerForm.valid) {
      const updateMethod = this.isPilejeRoute
        ? this.practitionerService.updatePilejeStatus
        : this.practitionerService.updateStatus;

      const requestBody: any = {
        firstName: this.practitionerForm.value.firstName,
        lastName: this.practitionerForm.value.lastName,
        identificationNumber: this.practitionerForm.value.identificationNumber,
        email: this.practitionerForm.value.email,
        phoneNumber: this.practitionerForm.value.phoneNumber,
        codeTiersCompte: this.practitionerForm.value.codeTiersCompte,
        codeContact: this.practitionerForm.value.codeContact,
        medicalDomain: this.practitionerForm.value.medicalDomain,
        address: this.practitionerForm.value.address,
        zipCode: this.practitionerForm.value.zipCode,
        city: this.practitionerForm.value.city,
        country: this.practitionerForm.value.country,
      };

      const password = this.practitionerForm.value.passwords.password;
      const confirmationPassword = this.practitionerForm.value.passwords
        .confirmationPassword;

      if (password || confirmationPassword) {
        requestBody.password = password;
        requestBody.confirmationPassword = confirmationPassword;
      }

      updateMethod
        .call(
          this.practitionerService,
          this.practitioner,
          this.practitionerId,
          requestBody
        )
        .subscribe(
          () => {
            this.succeed.emit('Modification effectuée');
          },
          (error) => {
            console.error('Échec de la mise à jour :', error);
          }
        );
    }
  }
}
