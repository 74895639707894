import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivationKeyService } from '../../services/activation-key.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private activationKeyService: ActivationKeyService,
    private renderer: Renderer2,
    private router: Router
  ) {}
  @ViewChild('logo', { static: false }) myLogo;
  events: string[] = [];
  opened: boolean;

  ngOnInit(): void {}
  activationKeyHandler() {
    this.activationKeyService.ordered_package.next(false);
  }
  companyHandler() {}
  over() {
    this.renderer.setStyle(this.myLogo.nativeElement, 'width', '100px');
    this.renderer.setStyle(this.myLogo.nativeElement, 'margin-left', '60px');
  }
  out() {
    this.renderer.setStyle(this.myLogo.nativeElement, 'width', '40px');
    this.renderer.setStyle(this.myLogo.nativeElement, 'margin-left', '10px');
  }

  welcomePageHandler() {
    this.router.navigate(['/dashboard/welcome']);
  }
}
