<h1 mat-dialog-title>Ajouter une nouvelle colonne</h1>
<div mat-dialog-content>
  <section class="example-section" [formGroup]="toppings">
    <h4>
      Veuillez sélectionner la colonne que vous souhaitez ajouter dans le
      tableau principal
    </h4>
    <fieldset>
      <legend>Colonnes remplissables</legend>
      <div class="row">
        <div class="column">
          <p>
            <mat-checkbox formControlName="bilan_type">bilan_type</mat-checkbox>
          </p>

          <p>
            <mat-checkbox formControlName="collection_date"
              >collection_date</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="expert_call_date"
              >expert_call_date</mat-checkbox
            >
          </p>
        </div>
        <div class="column">
          <p>
            <mat-checkbox formControlName="expert">expert</mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="location_site"
              >location_site</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="note">note</mat-checkbox>
          </p>
        </div>
        <div class="column">
          <p>
            <mat-checkbox formControlName="off_site">off_site</mat-checkbox>
          </p>

          <p>
            <mat-checkbox formControlName="survey1_date">
              survey1_date</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="survey_rate">
              survey_rate</mat-checkbox
            >
          </p>
        </div>
        <div class="column">
          <p>
            <mat-checkbox formControlName="survey2_date">
              survey2_date</mat-checkbox
            >
          </p>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Colonnes non remplissables</legend>
      <div class="row">
        <div class="column">
          <p><mat-checkbox formControlName="user_id">user_id</mat-checkbox></p>
          <p><mat-checkbox formControlName="company">origin</mat-checkbox></p>
          <p><mat-checkbox formControlName="date_of_birth">date_of_birth</mat-checkbox></p>

          <p>
            <mat-checkbox formControlName="reco_commited"
              >reco_commited</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="reco_viewed"
              >reco_viewed</mat-checkbox
            >
          </p>

          <p>
            <mat-checkbox formControlName="score_generated"
              >score_generated</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="score_entries_count"
              >score_entries_count</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="first_visit"
              >first_visit</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="score_viewed"
              >score_viewed</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="last_visit">last_visit</mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="last_bordereau_downloaded"
              >last_bordereau_downloaded</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="score_state_createdat"
              >score_state_createdat</mat-checkbox
            >
          </p>

          <p>
            <mat-checkbox formControlName="questioner_state_createdat"
              >questioner_state_createdat</mat-checkbox
            >
          </p>
        </div>
        <div class="column">
          <p>
            <mat-checkbox formControlName="questioner_domains_numbers"
              >questioner_domains_numbers</mat-checkbox
            >
          </p>

          <p>
            <mat-checkbox formControlName="reco_notified"
              >reco_notified</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="questioner_sent"
              >questioner_sent</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="questioner_id"
              >questioner_id</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="appointment_set"
              >appointment_set</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="data_analysis"
              >data_analysis</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="data_clinical"
              >data_clinical</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="indexes_viewed"
              >indexes_viewed</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="pdf_downloaded"
              >pdf_downloaded</mat-checkbox
            >
          </p>
        </div>
        <div class="column">
          <p>
            <mat-checkbox formControlName="phyto_viewed"
              >phyto_viewed</mat-checkbox
            >
          </p>

          <p>
            <mat-checkbox formControlName="phyto_ordered"
              >phyto_ordered</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="reco_checked"
              >reco_checked</mat-checkbox
            >
          </p>
          <p>
            <mat-checkbox formControlName="reco_checked_number"
              >reco_checked_number</mat-checkbox
            >
          </p>

          <p>
            <mat-checkbox formControlName="company_id">company_id</mat-checkbox>
          </p>

          <p>
            <mat-checkbox formControlName="activation_key"
              >activation_key</mat-checkbox
            >
          </p>

          <p>
            <mat-checkbox formControlName="key_type">key_type</mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="score_id">score_id</mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="plan_start_date">plan_start_date</mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="plan_expiration_date">plan_expiration_date</mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="plan_cancelled_date">plan_cancelled_date</mat-checkbox>    
          </p>
          <p>
            <mat-checkbox formControlName="plan_type">plan_type</mat-checkbox>    
          </p>
          <p>
            <mat-checkbox formControlName="plan_price">plan_price</mat-checkbox>    
          </p>
          <p>
            <mat-checkbox formControlName="plan_sponsor">plan_sponsor</mat-checkbox>    
          </p>
          <p>
            <mat-checkbox formControlName="plan_isActive">plan_isActive</mat-checkbox>    
          </p>
          
          
          
        </div>
      </div>
    </fieldset>
  </section>
</div>
<div mat-dialog-actions class="action-button-container">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="data.extra"
    cdkFocusInitial
    (click)="save()"
  >
    Save
  </button>
</div>
