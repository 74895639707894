<div class="edit-patient-container">
  <ng-container *ngIf="loaded">
    <ng-container *ngIf="isPatientRecord; then patientRecord; else editPatient"></ng-container>
    <ng-template #patientRecord>
      <ng-container *ngIf="requestRegister; then registerPatient; else showPatientRecord"></ng-container>
      <ng-template #registerPatient>
        <div class="box">
          <app-edit-patient-form [patient]="patient" translation="REGISTER-PATIENT-RECORD" [passwordRequired]="true"
            (formValues)="handleRegisterPatient($event)"></app-edit-patient-form>
        </div>
      </ng-template>
      <ng-template #showPatientRecord>
        <div class="box patient-record">
          <app-patient-record [patient]="patient" (registerRequest)="handleRegisterRequest()"></app-patient-record>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #editPatient>
      <div class="box">
        <app-patient-switch-status [patient]="patient" (switch)="handleSuccess($event)"></app-patient-switch-status>
        <app-edit-patient-form [patient]="patient" translation="EDIT-PATIENT-FORM" [passwordRequired]="false"
          (formValues)="handleEditPatient($event)"></app-edit-patient-form>
      </div>
      <div class="box">
        <app-credits-patient-form [patientCredit]="patientCredit" (succeed)="handleSuccess($event)"
          (submitError)="handleError($event)"></app-credits-patient-form>
      </div>
    </ng-template>

    <div>
      <h2>Scores</h2>
      <table mat-table [dataSource]="dataCredits" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>Created Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Updated Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.updatedAt | date }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>State</th>
          <td mat-cell *matCellDef="let element">{{ element.state }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <br>
      <h2>Abonnements</h2>
      <table mat-table [dataSource]="patientPlans" class="mat-elevation-z8">

        <!-- TYPE Column -->
        <ng-container matColumnDef="TYPE">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element"> {{ element.type }} </td>
        </ng-container>

        <!-- PRICE Column -->
        <ng-container matColumnDef="PRICE">
          <th mat-header-cell *matHeaderCellDef>Payé</th>
          <td mat-cell *matCellDef="let element">{{ element.price }}</td>
        </ng-container>

        <!-- START Column -->
        <ng-container matColumnDef="START">
          <th mat-header-cell *matHeaderCellDef>Date de début</th>
          <td mat-cell *matCellDef="let element"> {{ element.start_date | date }} </td>
        </ng-container>

        <!-- END Column -->
        <ng-container matColumnDef="END">
          <th mat-header-cell *matHeaderCellDef>Date de fin</th>
          <td mat-cell *matCellDef="let element">{{ element.expiration_date| date  }}</td>
        </ng-container>

        <!-- CANCEL Column -->
        <ng-container matColumnDef="CANCEL">
          <th mat-header-cell *matHeaderCellDef>Désabonnement</th>
          <td mat-cell *matCellDef="let element">{{ element.cancelled_date | date }}</td>
        </ng-container>

        <!-- ACTIVE Column -->
        <ng-container matColumnDef="ACTIVE">
          <th mat-header-cell *matHeaderCellDef>Actif</th>
          <td mat-cell *matCellDef="let element">{{ element.isActive ? "actif" : "expiré" }}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumnsPlans"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsPlans"></tr>
      </table>

    </div>
  </ng-container>
</div>