import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PractitionerService } from '../../services/practitioner.service';
import { map, switchMap, take } from 'rxjs/operators';
import { throwError, zip } from 'rxjs';
import {
  Practitioner,
  PractitionerCreditDto,
} from '../../../core/models/practitioner.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-practitioner',
  templateUrl: './edit-practitioner.component.html',
  styleUrls: ['./edit-practitioner.component.scss'],
})
export class EditPractitionerComponent implements OnInit {
  public loaded = false;
  public practitioner: Practitioner;
  public practitionerCredit: PractitionerCreditDto;
  public isPilejeRoute = false;
  public showToolbar: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private practitionerService: PractitionerService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const practitionerId = params.get('practitionerId');
      const url = this.route.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      this.showToolbar = url.includes(
        `pileje/practitioners/edit/${practitionerId}`
      );
      this.isPilejeRoute = this.showToolbar;
    });
    this.fetchPractitioner();
  }

  fetchPractitioner(): void {
    this.loaded = false;
    this.isPilejeRoute = this.route.snapshot.url.some(
      (segment) => segment.path === 'pileje'
    );

    this.route.paramMap
      .pipe(
        take(1),
        map((paramMap) => {
          const practitionerId = paramMap.get('practitionerId');
          if (practitionerId) {
            return practitionerId;
          }
          throwError("Aucune pièce d'identité fournie");
        }),
        switchMap((practitionerId: string) => {
          const practitionerObservable = this.isPilejeRoute
            ? this.practitionerService.getPilejePractitionerById(practitionerId)
            : this.practitionerService.getById(practitionerId);

          if (this.isPilejeRoute) {
            return practitionerObservable.pipe(take(1));
          } else {
            return zip(
              practitionerObservable,
              this.practitionerService.getCredits(practitionerId)
            ).pipe(take(1));
          }
        })
      )
      .subscribe((result) => {
        if (this.isPilejeRoute) {
          this.practitioner = result as Practitioner;
        } else {
          const [practitioner, practitionerCredit] = result as [
            Practitioner,
            PractitionerCreditDto
          ];
          this.practitioner = practitioner;
          this.practitionerCredit = practitionerCredit;
        }
        this.loaded = true;
      });
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, '', { duration: 5000 });
  }

  handleSuccess(message: string): void {
    this.openSnackBar(message);
    this.fetchPractitioner();
  }

  handleError(message: string): void {
    this.openSnackBar(message);
  }
}
