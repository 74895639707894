import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, Credentials } from '../services/auth.service';
import { Router } from '@angular/router';

interface PublicIpResponse {
  ip: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  // private readonly PILEJE_ADMIN_USERNAMES = [
  //   'pilejeAdmin',
  //   'pilejeAdmin1',
  //   'pilejeAdmin2',
  //   'pilejeAdmin3',
  //   'pilejeAdmin4',
  //   'pilejeAdmin5',
  //   'pilejeAdmin6',
  // ];

  private readonly PILEJE_ADMIN_USERNAMES = [
    's.david@pileje.com', // Séverine DAVID
    'b.lechat@pileje.com', // Blandine LECHAT
    'i.lebreton@pileje.com', // Isabelle LEBRETON
    'e.tuffreau@pileje.com', // Elodie TUFFREAU
    's.hardouin@pileje.com', // Sandy HARDOUIN
    'g.baumard@pileje.com', // Gaëlle BAUMARD
    'l.rethore@pileje.com', // Laëtitia RETHORE
    'interimsafd2@pileje.com', // Intérim 2
    'c.cardona@pileje.com', // Charline CARDONA
    'z.hadji@pileje.com', // Zinebe HADJI
    'a.pelloquin@pileje.com', // Auxane PELLOQUIN
    'a.berlin@pileje.com', // Aurélie BERLIN
    'b.huard@pileje.com', // Benjamin HUARD
    'a.dodier@pileje.com', // Alexandre DODIER
    't.decoha@pileje.com', // Théo DECOHA
    'myang+testdemo1@numahealth.com', // Maël test
    'myang+test2412082@numahealth.com', // Maël testpreprod
  ];

  private readonly NUMA_ADMIN_USERNAMES = [
    'pilejeAdmin', // pilejeAdmin
    'pilejeAdminNuma', // pilejeAdmin for NUMA
    'admin', // Admin
  ];

  IpAddressWideList = [
    // PILEJE
    '165.85.223.57',
    '165.85.223.5',
    '208.127.181.137',
    '208.127.181.138',
    '130.41.157.117',
    '130.41.157.202',
    '165.85.255.128',
    '128.77.84.147',
    '165.85.236.63',
    '165.85.236.64',
    '128.77.56.225',
    '90.63.228.70',
    '165.85.168.239',
    '165.85.168.240',
    '130.41.123.101',
    '130.41.123.102',
    '130.41.124.222',
    '130.41.242.56',
    '130.41.242.95',
    '165.85.254.130',
    '165.85.254.187',
    '165.85.254.188',
    '165.85.254.201',
    '64.187.131.162',
    '64.187.142.56',

    // THEO DECOHA
    '82.65.124.183',

    // IKOVA
    '93.23.129.109',
    '91.214.66.13',
    '84.239.67.49',

    //MAEL
    '88.183.3.147',
  ];
  public error : string = null;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  onSubmit(): void {
    this.error = null;
    if (this.loginForm.valid) {
      const credentials: Credentials = {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
      };

      this.authService
        .getPublicIpAddress()
        .subscribe((result: PublicIpResponse) => {
          const publicIp = result.ip;

          this.authService.login(credentials).subscribe(() => {
            if (this.PILEJE_ADMIN_USERNAMES.includes(credentials.username)) {
              if (this.IpAddressWideList.includes(publicIp)) {
                this.router.navigate(['pileje/practitioners']);
              } else {
                console.error(
                  'Access denied: Your IP address is not authorized to log in.'
                );
                //même chose en francais : 
                
                this.error = "Accès refusé: le lieu duquel vous accédez à cette page n'a pas été autorisé.";
              }
            } else {
              if (this.NUMA_ADMIN_USERNAMES.includes(credentials.username)) {
                if (credentials.username.includes('pileje')) {
                  this.router.navigate(['pileje/practitioners']);
                } else if (credentials.username.includes('admin')) {
                  this.router.navigate(['dashboard']);
                }
              } else {
                console.error(
                  'Access denied: Your username is not authorized to log in.'
                );
                this.error = "Accès refusé: ces identifiants n'ont pas été autorisés.";

              }
            }
          }, (error) => { 
            this.error = "Identifiants incorrects";
          }
        );
        });
    }
  }
}
