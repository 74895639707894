<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<mat-button-toggle-group name="modes" aria-label="modes" value="setup">
</mat-button-toggle-group>
<div class="flex_container">
  <div class="search">
    <span class="material-icons search-icon"> search </span>
    <input
      class="search-text"
      type="text"
      placeholder="Entrez votre texte ici..."
      [(ngModel)]="searchText"
      (ngModelChange)="onSearch($event)"
    />
  </div>
  <fieldset *ngIf="!showStates">
    <legend>Filters</legend>
    <div class="toggler">
      <table class="toggler-table">
        <tr>
          <td><span>Marqué</span></td>
          <td>
            <label class="switch">
              <input type="checkbox" (click)="onlyMarkedHandller()" />

              <span class="slider round"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td><span>Activé</span></td>
          <td>
            <label class="switch">
              <input type="checkbox" (click)="onlyActivatedHandaller()" />
              <span class="slider round"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td><span> Compte Activé</span></td>
          <td>
            <label class="switch">
              <input type="checkbox" (click)="onlyEnabledHanaller()" />
              <span class="slider round"></span>
            </label>
          </td>
        </tr>
      </table>
    </div>
  </fieldset>
</div>

<mat-button-toggle-group name="modes" aria-label="modes" value="client">
  <mat-button-toggle value="client" (click)="clientHandler()"
    >Clients</mat-button-toggle
  >
  <mat-button-toggle value="setup" (click)="setupHandler()"
    >Onboarding
  </mat-button-toggle>
  <mat-button-toggle value="evaluation" (click)="evaluationHandler()"
    >Pre-analysis</mat-button-toggle
  >
  <mat-button-toggle value="action" (click)="actionHandler()"
    >Post-Analysis</mat-button-toggle
  >
  <mat-button-toggle value="states" (click)="statesHandler()"
    >Stats
  </mat-button-toggle>
</mat-button-toggle-group>
<mat-button-toggle-group
  name="modes"
  aria-label="modes"
  value="dates"
  class="view-mode"
  *ngIf="showStates"
>
  <mat-button-toggle value="dates" (click)="viewModeHandler()"
    >Dates</mat-button-toggle
  >
  <mat-button-toggle value="checks" (click)="checkModeHandler()"
    >Checks</mat-button-toggle
  >
</mat-button-toggle-group>
<br />

<div *ngIf="noDataFound">
  <h3>No Data Found!</h3>
</div>
<div #table [hidden]="!showDashboard || (noDataFound && showStates)">
  <!-- <mat-form-field appearance="standard" class="filter filter-box">
    <mat-label class="search-label"
      ><mat-icon>search</mat-icon>Search</mat-label
    >

    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ex. Mael"
      #input
    />
  </mat-form-field> -->

  <table
    mat-table
    [dataSource]="dataSource"
    #mainSorter="matSort"
    matSort
    (matSortChange)="announceSortChange($event)"
    class="mat-elevation-z8"
  >
    <!-- Position Column -->
    <ng-container matColumnDef="mark">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by number"
      >
        Marque
      </th>
      <td mat-cell *matCellDef="let element">
        <app-star [userId]="element.user_id" [star]="element.star"></app-star>
      </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="last_name">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by number"
      >
        Nom
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.last_name }}
      </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="first_name">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by name"
      >
        Prénom
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activationKey">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Clé d'activation
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element.ak_id
            ? element.ak_id.length < 10
              ? element.ak_id
              : (element.ak_id | slice: 0:9) + '...'
            : 'N/A'
        }}
        <mat-icon
          class="copy"
          (click)="openSnackBar()"
          [cdkCopyToClipboard]="element.ak_id"
          >content_copy</mat-icon
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="etc">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Date de prélèvement
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Notes
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Enregistrer
      </th>
      <td mat-cell *matCellDef="let element" class="test">
        <app-store-info
          *ngIf="element.ak_id"
          [keyId]="element.ak_id ? element.ak_id : null"
          [userId]="element.user_id"
          [note]="element.note"
          [collectionDate]="element.collectionDate"
        ></app-store-info>
      </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Entreprise
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.name ? element.name : '' }}
        {{ element.company_id ? '(' + element.company_id + ')' : 'N/A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.type ? element.type : 'N/A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Date d'activation
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.created_at ? (element.created_at | date) : 'N/A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Dernier HP
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.report_created_at | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Email
      </th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="is_activated">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Clé
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="{{ element.is_activated ? 'enable' : 'disable' }}"
      >
        <!-- {{ element.is_activated }} -->
        <img
          src="../../../../assets/images/complete.png"
          width="30"
          *ngIf="element.is_activated"
        />
        <img
          src="../../../../assets/images/disable.png"
          width="30"
          *ngIf="!element.is_activated"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="enabled">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Compte
      </th>
      <td mat-cell *matCellDef="let element">
        <img
          src="../../../../assets/images/complete.png"
          width="30"
          *ngIf="element.enabled"
        />
        <img
          src="../../../../assets/images/disable.png"
          width="30"
          *ngIf="!element.enabled"
        />
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="lastEvaluationDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Last Evaluation Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastDate }}
        {{
          element.lastDate > 1
            ? 'days'
            : element.lastDate == 1 || element.lastDate == 0
            ? 'day'
            : 'N/A'
        }}
      </td>
    </ng-container> -->

    <tr
      class="mat-header-row"
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="rowClickHandller($event, row)"
      class="row-style"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>
  <mat-paginator
    #mainPaginator
    [length]="totalRows"
    [pageSize]="10"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
    [pageSizeOptions]="[10, 30, 50, 80, 100]"
  >
    >
  </mat-paginator>
</div>

<div class="states">
  <!-- <mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ex. Mia"
      #input
    />
  </mat-form-field> -->

  <br />
  <div class="set-up" [hidden]="!setup">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSourceStates"
        matSort
        #stateSorter="matSort"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>FirstName</th>
          <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LastName</th>
          <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="first_visit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>FirstVisit</th>
          <td mat-cell *matCellDef="let row">
            <!-- {{ row.first_visit ? (!viewMode ? row.first_visit : 'N/A') : ' N/A' }} -->
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.first_visit ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.first_visit ? (row.first_visit | date) : 'N/A' }}</span
            >
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="last_visit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LastVisit</th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.last_visit ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode"
              >{{ row.last_visit ? (row.last_visit | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="activation_key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            ActivationKey
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.activation_key ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.activation_key ? (row.activation_key | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="questionnaire_sent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            QuestionerSent
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.questionnaire_sent ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{
                row.questionnaire_sent ? (row.questionnaire_sent | date) : 'N/A'
              }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="score_generated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            ScoreGenerated
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.score_generated ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>

            <span *ngIf="!viewMode">
              {{ row.score_generated ? (row.score_generated | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsSetup"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsSetup"
          (click)="rowClickHandller($event, row)"
        ></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>

      <mat-paginator
        #statePaginator
        [pageSize]="100"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        [pageSizeOptions]="[10, 30, 50, 100, 150]"
      >
        >
      </mat-paginator>
    </div>
  </div>
  <div class="evaluation" [hidden]="!evaluation">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSourceEvaluation"
        matSort
        #evaluationSorter="matSort"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            FirstName
          </th>
          <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LastName</th>
          <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="last_visit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            LastVisit
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.last_visit ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.last_visit ? (row.last_visit | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="questionnaire_sent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            QuestionerSent
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.questionnaire_sent ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{
                row.questionnaire_sent ? (row.questionnaire_sent | date) : 'N/A'
              }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="questionnaire_domains_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            QuestionerNB
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.questionnaire_domains_number ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{
                row.questionnaire_domains_number
                  ? row.questionnaire_domains_number
                  : 'N/A'
              }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="data_stress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>data_stress</th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.data_stress ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.data_stress ? (row.data_stress | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="data_clinical">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            data_clinical
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.data_clinical ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.data_clinical ? (row.data_clinical | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="data_analysis">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            data_analysis
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.data_analysis ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.data_analysis ? (row.data_analysis | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="score_generated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            score_generated
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.score_generated ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.score_generated ? (row.score_generated | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="score_viewed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            score_viewed
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.score_viewed ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.score_viewed ? (row.score_viewed | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsEvaluation"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsEvaluation"
          (click)="rowClickHandller($event, row)"
        ></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>

      <mat-paginator
        #evaluationPaginator
        [pageSize]="100"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        [pageSizeOptions]="[10, 30, 50, 100, 150]"
      ></mat-paginator>
    </div>
  </div>
  <div class="action" [hidden]="!action">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSourceAction"
        matSort
        #actionSorter="matSort"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            FirstName
          </th>
          <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LastName</th>
          <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="last_visit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            LastVisit
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.last_visit ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.last_visit ? (row.last_visit | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="score_viewed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            score_viewed
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.score_viewed ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.score_viewed ? (row.score_viewed | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="video_viewed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            video_viewed
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.video_viewed ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.video_viewed ? (row.video_viewed | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="pdf_downloaded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            pdf_downloaded
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.pdf_downloaded ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.pdf_downloaded ? row.pdf_downloaded : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="phyto_clicked">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            phyto_clicked
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.phyto_clicked ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.phyto_clicked ? (row.phyto_clicked | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="indexes_viewed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            indexes_viewed
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.indexes_viewed ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.indexes_viewed ? (row.indexes_viewed | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="reco_viewed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            reco_viewed
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.reco_viewed ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.reco_viewed ? (row.reco_viewed | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->

        <tr mat-header-row *matHeaderRowDef="displayedColumnsAction"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsAction"
          (click)="rowClickHandller($event, row)"
        ></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>

      <mat-paginator
        #actionPaginator
        [pageSize]="100"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        [pageSizeOptions]="[10, 30, 50, 100, 150]"
      ></mat-paginator>
    </div>
  </div>
  <div class="states" [hidden]="!states">
    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSourceStats"
        matSort
        #statsSorter="matSort"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            FirstName
          </th>
          <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LastName</th>
          <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="last_visit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            LastVisit
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.last_visit ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.last_visit ? (row.last_visit | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="score_generated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            score_generated
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.score_generated ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.score_generated ? (row.score_generated | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="questionnaire_domains_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            questionnaire_domains_number
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.questionnaire_domains_number ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{
                row.questionnaire_domains_number
                  ? row.questionnaire_domains_number
                  : 'N/A'
              }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="appointment_set">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            appointment_set
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.appointment_set ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.appointment_set ? (row.appointment_set | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="phyto_ordered">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            phyto_ordered
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.phyto_ordered ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.phyto_ordered ? (row.phyto_ordered | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="reco_checked_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            reco_checked_number
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.reco_checked_number ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.reco_checked_number ? row.reco_checked_number : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="reco_commited">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            reco_commited
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.reco_commited ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.reco_commited ? (row.reco_commited | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="reco_notified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            reco_notified
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              class="material-icons"
              *ngIf="viewMode"
              class="{{ row.reco_notified ? 'green' : 'gray' }}"
            >
              fiber_manual_record
            </span>
            <span *ngIf="!viewMode">
              {{ row.reco_notified ? (row.reco_notified | date) : 'N/A' }}
            </span>
          </td>
        </ng-container>

        <!-- Fruit Column -->

        <tr mat-header-row *matHeaderRowDef="displayedColumnsStates"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsStates"
          (click)="rowClickHandller($event, row)"
        ></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>

      <mat-paginator
        #statsPaginator
        [pageSize]="100"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        [pageSizeOptions]="[10, 30, 50, 100, 150]"
      ></mat-paginator>
    </div>
  </div>
</div>
