<div>
  <td>
    <mat-form-field appearance="fill" class="date-picker">
      <mat-label>Choose a date</mat-label>
      <input [(ngModel)]="collectionDate" matInput [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </td>
  <td class="text-area">
    <textarea
      class="notes-text-area"
      placeholder="Notes..."
      rows="3"
      cols="10"
      [(ngModel)]="notes"
    ></textarea>
  </td>
  <td>
    <button
      mat-flat-button
      color="primary"
      class="save-button"
      disabled="{{ !notes && !collectionDate }}"
      (click)="storeData()"
    >
      <mat-icon>save</mat-icon>
    </button>
    <span *ngIf="dataSaved">Saved!</span>
  </td>
</div>
