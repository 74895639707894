<mat-progress-bar
  *ngIf="isLoading"
  [value]="loadingProgress"
  mode="determinate"
>
</mat-progress-bar>

<app-patients-list (rowClick)="handleRowClick($event)"></app-patients-list>
<mat-paginator
  [length]="patientPagination?.meta.totalItems"
  [pageSize]="patientPagination?.meta.itemsPerPage"
  (page)="handlePage($event)"
  [pageSizeOptions]="[20, 40, 50, 70, 100]"
  (page)="handlePage($event)"
></mat-paginator>
