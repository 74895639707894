<br />
<div class="key-request">
  <fieldset>
    <legend>
      <h1>
        Enregistrer manuellement une demande de clé dans la base de données
      </h1>
    </legend>
    <mat-form-field class="example-form-field" appearance="fill">
      <mat-label>Email</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="mail_address"
        placeholder="alim@numahalth.com"
      />
      <button
        *ngIf="mail_address"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="mail_address = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="example-form-field" appearance="fill">
      <mat-label>Quantité</mat-label>
      <input matInput type="text" [(ngModel)]="quantity" placeholder="6" />
      <button
        *ngIf="quantity"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="quantity = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Campagne</mat-label>
      <mat-select [(ngModel)]="selected_key_campaign" name="food">
        <mat-option *ngFor="let campaign of campaigns" [value]="campaign">
          {{ campaign.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="saveKeyRquest()">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
    <span *ngIf="error" class="error">Veuillez remplir les entrées</span>
    <span *ngIf="saved" class="saved"
      >La demande de clé a été enregistrée !</span
    >
  </fieldset>
</div>
<fieldset *ngIf="!ordered_package_ready">
  <legend><h1>Veuillez sélectionner votre package à générer</h1></legend>
  <div class="container">
    <app-product-card
      *ngFor="let product of all_product_types"
      [title]="product.name"
      [type]="product.type"
      [content_object_full]="product.full"
      [content_object_treatment]="product.treatment"
      [content_object_consultation]="product.consultation"
      [content_object_box]="product.box"
    ></app-product-card>
  </div>
</fieldset>

<fieldset *ngIf="ordered_package_ready">
  <legend><h1>Les clés suivantes ont été générées avec succès</h1></legend>
  <app-order-result></app-order-result>
</fieldset>
