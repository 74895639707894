<div>
  <fieldset>
    <legend>Activation Keys</legend>

    <ol>
      <h3 *ngFor="let activationKey of ordered_package_data">
        <li>
          {{ activationKey.id }}
        </li>
      </h3>
    </ol>
  </fieldset>
</div>
