import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Patient,
  PatientCreditDto,
  PatientPaginationDto,
  PatientPlanDto,
  UpdateCreditDto,
  UpdatePatientDto,
} from '../../core/models/patient.model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { PatchEmptyError } from '../../core/errors/patch-empty.error';
import { MatTableDataSource } from '@angular/material/table';

@Injectable()
export class PatientService {
  private patientUrl = environment.api.authUrl + '/patients';
  private commercialPatientUrl = environment.api.commercialUrl + '/patients';
  private commercialPlansUrl = environment.api.commercialUrl + '/plans';
  private commercialScores = environment.api.commercialUrl + '/scores';
  private readonly registerPatientEndpoint: string;
  patientList = new BehaviorSubject<Patient[]>(null);

  constructor(private http: HttpClient) {
    this.registerPatientEndpoint = this.patientUrl + '/register';
  }

  getAll(page?: number, limit?: number): Observable<PatientPaginationDto> {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (limit) {
      params = params.set('limit', limit.toString());
    }
    return this.http.get<PatientPaginationDto>(`${this.patientUrl}`, {
      params,
    });
  }

  getById(id: string): Observable<Patient> {
    return this.http.get<Patient>(`${this.patientUrl}/${id}`);
  }

  getCredits(patientId: string): Observable<PatientCreditDto> {
    return this.http.get<PatientCreditDto>(
      `${this.commercialPatientUrl}/${patientId}/credits`
    );
  }

  getPlans(patientId: string): Observable<PatientPlanDto[]> {
    return this.http.get<PatientPlanDto[]>(
      `${this.commercialPlansUrl}/${patientId}/plans`
    );
  }

  updateCredits(
    patientCredit: PatientCreditDto,
    updatedCredit: UpdateCreditDto
  ): Observable<any> {
    const toUpdate = {};
    for (const field in updatedCredit) {
      if (updatedCredit.hasOwnProperty(field)) {
        if (updatedCredit[field] !== patientCredit.credits[field]) {
          toUpdate[field] = updatedCredit[field];
        }
      }
    }

    if (Object.keys(toUpdate).length === 0) {
      return throwError(
        new PatchEmptyError('Aucun changement sur les crédits')
      );
    }

    return this.http.patch(
      `${this.commercialPatientUrl}/${patientCredit.patientId}/credits`,
      toUpdate
    );
  }

  update(patient: Patient, updatedPatient: UpdatePatientDto): Observable<any> {
    const toUpdate = {};
    for (const field in updatedPatient) {
      if (updatedPatient.hasOwnProperty(field)) {
        if (field === 'password' || field === 'confirmationPassword') {
          if (
            updatedPatient.password &&
            updatedPatient.password === updatedPatient.confirmationPassword
          ) {
            toUpdate[field] = updatedPatient[field];
          }
        } else if (updatedPatient[field] !== patient[field]) {
          toUpdate[field] = updatedPatient[field];
        }
      }
    }

    if (Object.keys(toUpdate).length === 0) {
      return throwError(new PatchEmptyError('Aucun changement sur le patient'));
    }

    return this.http.patch(`${this.patientUrl}/${patient.id}`, toUpdate);
  }

  updateStatus(patient: Patient, enabled: boolean): Observable<any> {
    return this.http.patch(`${this.patientUrl}/${patient.id}`, {
      enabled,
    });
  }

  registerByPatientCode(
    patient: Patient,
    values: UpdatePatientDto
  ): Observable<any> {
    const registerPatient = new Patient();
    registerPatient.lastName = values.lastName;
    registerPatient.firstName = values.firstName;
    registerPatient.phoneNumber = values.phoneNumber;
    registerPatient.gender = values.gender;
    registerPatient.email = values.email;
    registerPatient.socialNumber = values.socialNumber;
    registerPatient.dateOfBirth = values.dateOfBirth;
    registerPatient.password = values.password;
    registerPatient.confirmationPassword = values.confirmationPassword;

    return this.http.put(
      `${this.registerPatientEndpoint}/${patient.id}/${patient.patientCode}`,
      registerPatient
    );
  }

  getScoresByPatientId(patientId) {
    return this.http.get(`${this.commercialScores}/patients/${patientId}`);
  }
}
