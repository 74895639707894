<div class="company-container">
  <mat-card>
    <mat-card-title>Companies</mat-card-title>
    <!-- <div class="search">
      <span class="material-icons search-icon"> search </span>
      <input
        class="search-text"
        type="text"
        placeholder="Entrez votre texte ici..."
        [(ngModel)]="searchText"
        (ngModelChange)="onSearch($event)"
      />
    </div> -->

    <br />
    <mat-form-field class="search-box">
      <mat-label>Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ex. ium"
        #input
        [(ngModel)]="searchText"
      />
    </mat-form-field>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="domain">
          <th mat-header-cell *matHeaderCellDef>Domain</th>
          <td mat-cell *matCellDef="let element">{{ element.domain }}</td>
        </ng-container>

        <ng-container matColumnDef="isDeleted">
          <th mat-header-cell *matHeaderCellDef>isDeleted</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isDeleted ? 'Oui' : 'Non' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>CreatedAt</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date }}
          </td>
        </ng-container>
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Details</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon
              (click)="onShowDetials(element.id)"
              class="material-symbols-outlined"
            >
              aspect_ratio</mat-icon
            >
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onNavigate(row)"
        ></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[20, 40, 50, 70, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </mat-card>
</div>
