<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="top-bar">
  <mat-button-toggle-group
    name="modes"
    aria-label="modes"
    value="{{ currentView ? currentView.name : 'Default' }}"
  >
    <mat-button-toggle
      *ngFor="let view of views"
      value="{{ view.name }}"
      (click)="updateView(view.name)"
      >{{ view.name }}</mat-button-toggle
    >
  </mat-button-toggle-group>

  <div>
    <button
      mat-mini-fab
      color="primary"
      disabled="{{ !temporaryStorage.length && !this.contentIsModified }}"
      class="no-shadow"
      (click)="dataSaveHandler()"
      matTooltip="Sauvegarder"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      aria-label="add"
      mat-mini-fab
      color="primary"
      class="no-shadow"
      aria-label="Add new view"
      (click)="addColumn()"
      matTooltip="Ajouter une nouvelle vue"
    >
      <mat-icon>add</mat-icon>
    </button>

    <button
      mat-mini-fab
      color="primary"
      class="no-shadow"
      (click)="export()"
      matTooltip="Exporter au format excel"
    >
      <mat-icon>system_update_alt</mat-icon>
    </button>
    <button
      mat-mini-fab
      color="primary"
      class="no-shadow"
      (click)="openDialog()"
      matTooltip="Ajouter une nouvelle colonne"
    >
      <mat-icon>apps</mat-icon>
    </button>
    <button
      matTooltip="Supprimer"
      mat-mini-fab
      color="warn"
      class="no-shadow"
      (click)="deleteView()"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <mat-button-toggle-group
      name="modes"
      aria-label="modes"
      value="latest"
      class="view-mode"
    >
      <mat-button-toggle value="latest" (click)="latestStorageStateHandler()"
        >Dernière</mat-button-toggle
      >
      <mat-button-toggle value="all" (click)="allStorageStateHandler()"
        >Toute</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
</div>

<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="star_state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Marque</th>
      <td mat-cell *matCellDef="let element">
        <app-star
          [userId]="element.user_id"
          [scoreId]="element.score_id"
        ></app-star>
      </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="last_name" sticky="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="first_name" sticky="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Prénom</th>
      <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
    </ng-container>
    <ng-container matColumnDef="company" sticky="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{
        aliasList
          ? aliasList.company
            ? aliasList.company
            : 'origin'
          : 'origin'
      }}<mat-icon class="edit" (click)="editColumn('origin')"
        >edit</mat-icon
      ></th>
      <td mat-cell *matCellDef="let element">{{ element.company }}</td>
    </ng-container>
    <ng-container matColumnDef="date_of_birth" sticky="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{
        aliasList
          ? aliasList.date_of_birth
            ? aliasList.date_of_birth
            : 'date_of_birth'
          : 'date_of_birth'
      }}<mat-icon class="edit" (click)="editColumn('date_of_birth')"
        >edit</mat-icon
      ></th>
      <td mat-cell *matCellDef="let element">{{ element.date_of_birth | date}}</td>
    </ng-container>

    <ng-container matColumnDef="first_visit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.first_visit
              ? aliasList.first_visit
              : 'First Visit'
            : 'First Visit'
        }}<mat-icon class="edit" (click)="editColumn('first_visit')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_visit | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="company_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.company_name
              ? aliasList.company_name
              : 'company_name'
            : 'company_name'
        }}<mat-icon class="edit" (click)="editColumn('company_name')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.company_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ aliasList ? (aliasList.email ? aliasList.email : 'email') : 'email'
        }}<mat-icon class="edit" (click)="editColumn('email')">edit</mat-icon>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.email }}
      </td>
    </ng-container>
    <ng-container matColumnDef="campaign_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.campaign_id
              ? aliasList.campaign_id
              : 'campaign_id'
            : 'campaign_id'
        }}<mat-icon class="edit" (click)="editColumn('campaign_id')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.campaign_id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="last_visit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.last_visit
              ? aliasList.last_visit
              : 'Last Visit'
            : 'Last Visit'
        }}<mat-icon class="edit" (click)="editColumn('last_visit')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.last_visit | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="plan_start_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.plan_start_date
              ? aliasList.plan_start_date
              : 'plan_start_date'
            : 'plan_start_date'
        }}<mat-icon class="edit" (click)="editColumn('plan_start_date')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.plan_start_date | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="plan_expiration_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.plan_expiration_date
              ? aliasList.plan_expiration_date
              : 'plan_expiration_date'
            : 'plan_expiration_date'
        }}<mat-icon class="edit" (click)="editColumn('plan_expiration_date')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.plan_expiration_date | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="plan_cancelled_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.plan_cancelled_date
              ? aliasList.plan_cancelled_date
              : 'plan_cancelled_date'
            : 'plan_cancelled_date'
        }}<mat-icon class="edit" (click)="editColumn('plan_cancelled_date')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.plan_cancelled_date | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="plan_sponsor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.plan_sponsor
              ? aliasList.plan_sponsor
              : 'plan_sponsor'
            : 'plan_sponsor'
        }}<mat-icon class="edit" (click)="editColumn('plan_sponsor')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.plan_sponsor }}
      </td>
    </ng-container>

    <ng-container matColumnDef="plan_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.plan_price
              ? aliasList.plan_price
              : 'plan_price'
            : 'plan_price'
        }}<mat-icon class="edit" (click)="editColumn('plan_price')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.plan_price }}
      </td>
    </ng-container>

    <ng-container matColumnDef="plan_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.plan_type
              ? aliasList.plan_type
              : 'plan_type'
            : 'plan_type'
        }}<mat-icon class="edit" (click)="editColumn('plan_type')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.plan_type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="plan_isActive">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.plan_isActive
              ? aliasList.plan_isActive
              : 'plan_isActive'
            : 'plan_isActive'
        }}<mat-icon class="edit" (click)="editColumn('plan_isActive')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.plan_isActive}}
      </td>
    </ng-container>
   

    <ng-container matColumnDef="last_bordereau_downloaded">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.last_bordereau_downloaded
              ? aliasList.last_bordereau_downloaded
              : 'last_bordereau_downloaded'
            : 'last_bordereau_downloaded'
        }}<mat-icon
          class="edit"
          (click)="editColumn('last_bordereau_downloaded')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.last_bordereau_downloaded | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="appointment_set">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.appointment_set
              ? aliasList.appointment_set
              : 'appointment_set'
            : 'appointment_set'
        }}<mat-icon class="edit" (click)="editColumn('appointment_set')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.appointment_set | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="data_analysis">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.data_analysis
              ? aliasList.data_analysis
              : 'data_analysis'
            : 'data_analysis'
        }}<mat-icon class="edit" (click)="editColumn('data_analysis')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.data_analysis | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="data_clinical">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.data_clinical
              ? aliasList.data_clinical
              : 'data_clinical'
            : 'data_clinical'
        }}<mat-icon class="edit" (click)="editColumn('data_clinical')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.data_clinical | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="data_stress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.data_stress
              ? aliasList.data_stress
              : 'data_stress'
            : 'data_stress'
        }}<mat-icon class="edit" (click)="editColumn('data_stress')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.data_stress | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="indexes_viewed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.indexes_viewed
              ? aliasList.indexes_viewed
              : 'indexes_viewed'
            : 'indexes_viewed'
        }}<mat-icon class="edit" (click)="editColumn('indexes_viewed')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.indexes_viewed | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="pdf_downloaded">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.pdf_downloaded
              ? aliasList.pdf_downloaded
              : 'pdf_downloaded'
            : 'pdf_downloaded'
        }}<mat-icon class="edit" (click)="editColumn('pdf_downloaded')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.pdf_downloaded | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="phyto_viewed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.phyto_viewed
              ? aliasList.phyto_viewed
              : 'phyto_viewed'
            : 'phyto_viewed'
        }}<mat-icon class="edit" (click)="editColumn('phyto_viewed')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.phyto_viewed | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="phyto_ordered">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.phyto_ordered
              ? aliasList.phyto_ordered
              : 'phyto_ordered'
            : 'phyto_ordered'
        }}<mat-icon class="edit" (click)="editColumn('phyto_ordered')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.phyto_ordered | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="reco_checked">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.reco_checked
              ? aliasList.reco_checked
              : 'reco_checked'
            : 'reco_checked'
        }}<mat-icon class="edit" (click)="editColumn('reco_checked')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.reco_checked | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="reco_checked_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.reco_checked_number
              ? aliasList.reco_checked_number
              : 'reco_checked_number'
            : 'reco_checked_number'
        }}<mat-icon class="edit" (click)="editColumn('reco_checked_number')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.reco_checked_number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="reco_commited">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.reco_commited
              ? aliasList.reco_commited
              : 'reco_commited'
            : 'reco_commited'
        }}<mat-icon class="edit" (click)="editColumn('reco_commited')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.reco_commited | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="reco_notified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.reco_notified
              ? aliasList.reco_notified
              : 'reco_notified'
            : 'reco_notified'
        }}<mat-icon class="edit" (click)="editColumn('reco_notified')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.reco_notified | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="reco_viewed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.reco_viewed
              ? aliasList.reco_viewed
              : 'reco_viewed'
            : 'reco_viewed'
        }}<mat-icon class="edit" (click)="editColumn('reco_viewed')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.reco_viewed | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="score_generated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.score_generated
              ? aliasList.score_generated
              : 'score_generated'
            : 'score_generated'
        }}<mat-icon class="edit" (click)="editColumn('score_generated')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.score_generated | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.user_id
              ? aliasList.user_id
              : 'user_id'
            : 'user_id'
        }}<mat-icon class="edit" (click)="editColumn('user_id')">edit</mat-icon>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.user_id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="score_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.score_id
              ? aliasList.score_id
              : 'score_id'
            : 'score_id'
        }}<mat-icon class="edit" (click)="editColumn('score_id')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">{{ element.score_id }}</td>
    </ng-container>

    <ng-container matColumnDef="score_viewed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.score_viewed
              ? aliasList.score_viewed
              : 'score_viewed'
            : 'score_viewed'
        }}<mat-icon class="edit" (click)="editColumn('score_viewed')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.score_viewed | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="score_entries_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.score_entries_count
              ? aliasList.score_entries_count
              : 'score_entries_count'
            : 'score_entries_count'
        }}<mat-icon class="edit" (click)="editColumn('score_entries_count')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.score_entries_count }}
      </td>
    </ng-container>

    <ng-container matColumnDef="score_state_createdat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.score_state_createdat
              ? aliasList.score_state_createdat
              : 'score_state_createdat'
            : 'score_state_createdat'
        }}<mat-icon class="edit" (click)="editColumn('score_state_createdat')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.score_state_createdat | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="questioner_domains_numbers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.questioner_domains_numbers
              ? aliasList.questioner_domains_numbers
              : 'questioner_domains_numbers'
            : 'questioner_domains_numbers'
        }}<mat-icon
          class="edit"
          (click)="editColumn('questioner_domains_numbers')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.questioner_domains_numbers }}
      </td>
    </ng-container>
    <ng-container matColumnDef="questioner_sent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.questioner_sent
              ? aliasList.questioner_sent
              : 'questioner_sent'
            : 'questioner_sent'
        }}<mat-icon class="edit" (click)="editColumn('questioner_sent')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.questioner_sent | date }}
      </td>
    </ng-container>
    <ng-container matColumnDef="questioner_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.questioner_id
              ? aliasList.questioner_id
              : 'questioner_id'
            : 'questioner_id'
        }}<mat-icon class="edit" (click)="editColumn('questioner_id')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">{{ element.questioner_id }}</td>
    </ng-container>
    <ng-container matColumnDef="questioner_state_createdat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.questioner_state_createdat
              ? aliasList.questioner_state_createdat
              : 'questioner_state_createdat'
            : 'questioner_state_createdat'
        }}<mat-icon
          class="edit"
          (click)="editColumn('questioner_state_createdat')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.questioner_state_createdat | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="key_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.key_type
              ? aliasList.key_type
              : 'key_type'
            : 'key_type'
        }}<mat-icon class="edit" (click)="editColumn('key_type')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.key_type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activation_key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.activation_key
              ? aliasList.activation_key
              : 'activation_key'
            : 'activation_key'
        }}<mat-icon class="edit" (click)="editColumn('activation_key')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.activation_key }}
      </td>
    </ng-container>
    <ng-container matColumnDef="company_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.company_id
              ? aliasList.company_id
              : 'company_id'
            : 'company_id'
        }}<mat-icon class="edit" (click)="editColumn('company_id')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.company_id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="bilan_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.bilan_type
              ? aliasList.bilan_type
              : 'bilan_type'
            : 'bilan_type'
        }}<mat-icon class="edit" (click)="editColumn('bilan_type')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill">
          <mat-label>Select an option</mat-label>
          <mat-select
            [value]="element.bilan_type"
            disableRipple
            (selectionChange)="
              temporarySaveHandler(
                element.score_id,
                'bilan_type',
                $event.value,
                element.user_id
              )
            "
          >
            <mat-option value="Essential">Essential</mat-option>
            <mat-option value="Boost">Boost</mat-option>
            <mat-option value="Autre">Autre</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="location_site">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.location_site
              ? aliasList.location_site
              : 'location_site'
            : 'location_site'
        }}<mat-icon class="edit" (click)="editColumn('location_site')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <input
          type="text"
          value="{{ element.site_location }}"
          (change)="
            temporarySaveHandler(
              element.score_id,
              'site_location',
              $event.target.value,
              element.user_id
            )
          "
          (input)="noteChangeDetector()"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="off_site">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.off_site
              ? aliasList.off_site
              : 'off_site'
            : 'off_site'
        }}<mat-icon class="edit" (click)="editColumn('off_site')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill">
          <mat-label>{{ element.off_site ? 'Yes' : 'No' }}</mat-label>
          <mat-select
            [value]="element.off_site ? 'Yes' : 'No'"
            disableRipple
            (selectionChange)="
              temporarySaveHandler(
                element.score_id,
                'off_site',
                $event.value,
                element.user_id
              )
            "
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="expert_call_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.expert_call_date
              ? aliasList.expert_call_date
              : 'expert_call_date'
            : 'expert_call_date'
        }}<mat-icon class="edit" (click)="editColumn('expert_call_date')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" class="date-picker">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            (dateChange)="
              temporarySaveHandler(
                element.score_id,
                'expert_call_date',
                $event.value,
                element.user_id
              )
            "
            [matDatepicker]="picker"
            value="{{ element.expert_call_date }}"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="expert">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.expert
              ? aliasList.expert
              : 'expert'
            : 'expert'
        }}<mat-icon class="edit" (click)="editColumn('expert')">edit</mat-icon>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill">
          <mat-label>Select an option</mat-label>
          <mat-select
            disableRipple
            [value]="element.expert"
            (selectionChange)="
              temporarySaveHandler(
                element.score_id,
                'expert',
                $event.value,
                element.user_id
              )
            "
          >
            <mat-option value="Alim">Manda</mat-option>
            <mat-option value="Alim2">Thomas</mat-option>
            <mat-option value="Alim3">Autre</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="collection_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.collection_date
              ? aliasList.collection_date
              : 'collection_date'
            : 'collection_date'
        }}<mat-icon class="edit" (click)="editColumn('collection_date')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" class="date-picker">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            (dateChange)="
              temporarySaveHandler(
                element.score_id,
                'collection_date',
                $event.value,
                element.user_id
              )
            "
            [matDatepicker]="picker"
            value="{{ element.collection_date }}"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="survey1_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.survey1_date
              ? aliasList.survey1_date
              : 'survey1_date'
            : 'survey1_date'
        }}<mat-icon class="edit" (click)="editColumn('survey1_date')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" class="date-picker">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            (dateChange)="
              temporarySaveHandler(
                element.score_id,
                'survey1_date',
                $event.value,
                element.user_id
              )
            "
            [matDatepicker]="picker"
            value="{{ element.survey1_date }}"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="survey2_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.survey2_date
              ? aliasList.survey2_date
              : 'survey2_date'
            : 'survey2_date'
        }}<mat-icon class="edit" (click)="editColumn('survey2_date')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" class="date-picker">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            (dateChange)="
              temporarySaveHandler(
                element.score_id,
                'survey2_date',
                $event.value,
                element.user_id
              )
            "
            [matDatepicker]="picker"
            value="{{ element.survey2_date }}"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="survey_rate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          aliasList
            ? aliasList.survey_rate
              ? aliasList.survey_rate
              : 'survey_rate'
            : 'survey_rate'
        }}<mat-icon class="edit" (click)="editColumn('survey_rate')"
          >edit</mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
        <!--  <textarea></textarea> -->
        <input
          type="text"
          value="{{ element.survey_rate }}"
          (change)="
            temporarySaveHandler(
              element.score_id,
              'survey_rate',
              $event.target.value,
              element.user_id
            )
          "
          (input)="noteChangeDetector()"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ aliasList ? (aliasList.note ? aliasList.note : 'note') : 'note'
        }}<mat-icon class="edit" (click)="editColumn('note')">edit</mat-icon>
      </th>
      <td mat-cell *matCellDef="let element">
        <textarea
          type="text"
          value="{{ element.note }}"
          (change)="
            temporarySaveHandler(
              element.score_id,
              'note',
              $event,
              element.user_id
            )
          "
          (input)="noteChangeDetector()"
        ></textarea>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="rowClickHandler($event, row)"
    ></tr>
  </table>
</div>
<mat-paginator
  [pageSizeOptions]="[50, 100, 200]"
  aria-label="Select page of users"
  class="mat-paginator-sticky"
></mat-paginator>
