<div class="credits-form-container">
  <form [formGroup]="creditsForm" (ngSubmit)="handleSubmit()">
    <section>
      <h2>Crédits</h2>
      <div class="input-row">
        <app-input
          type="number"
          formControlName="full"
          label="Profil de santé"
          [value]="getFormValue('full')"
        ></app-input>
        <app-input
          type="number"
          formControlName="treatment"
          label="Mois de traitement"
          [value]="getFormValue('treatment')"
        ></app-input>
        <app-input
          type="number"
          formControlName="box"
          label="Boîte de traitement"
          [value]="getFormValue('box')"
        ></app-input>
        <app-input
          type="number"
          formControlName="consultation"
          label="Consultation"
          [value]="getFormValue('consultation')"
        ></app-input>
        <app-input
          type="number"
          formControlName="shortconsultation"
          label="Short Consultation"
          [value]="getFormValue('shortconsultation')"
        ></app-input>
      </div>
    </section>
    <button mat-raised-button color="primary" type="submit">Enregistrer</button>
  </form>
</div>
