import { FormBuilder } from '@angular/forms';

import { StatesService } from '../states.service';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
@Component({
  selector: 'add-column.dialog',
  templateUrl: 'add-view.dialog.html',
})
export class AddViewDialog implements OnInit {
  newName: string;
  columnName: any;
  constructor(
    public dialogRef: MatDialogRef<AddViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private statesService: StatesService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.columnName = this.data.columnName;
  }
  save() {
    let newView = {
      name: this.newName,
      value: {
        user_id: false,
        company: false,
        date_of_birth: false,
        plan_start_date: false,
        plan_expiration_date: false,
        plan_cancelled_date: false,
        plan_type: false,
        plan_price: false,
        plan_sponsor: false,  
        plan_isActive: false,  
        score_id: false,
        last_visit: false,
        data_stress: false,
        first_visit: false,
        reco_viewed: false,
        reco_checked: false,
        score_viewed: false,
        data_analysis: false,
        data_clinical: false,
        phyto_viewed: false,
        phyto_ordered: false,
        questioner_id: false,
        reco_commited: false,
        reco_notified: false,
        appointment_set: false,
        indexes_viewed: false,
        pdf_downloaded: false,
        questioner_sent: false,
        score_generated: false,
        reco_checked_number: false,
        score_state_createdat: false,
        last_bordereau_downloaded: false,
        questioner_domains_numbers: false,
        questioner_state_createdat: false,
        email: false,
        campaign_id: false,
        company_id: false,
        company_name: false,
        activation_key: false,
        key_type: false,
        bilan_type: false,
        location_site: false,
        off_site: false,
        expert_call_date: false,
        expert: false,
        survey1_date: false,
        survey2_date: false,
        survey_rate: false,
        collection_date: false,
        note: false,
        score_entries_count: false,
      },
    };
    let currentViewStorage = this.statesService.viewsStorage.getValue();
    currentViewStorage.push(newView);
    this.statesService.saveView(newView).subscribe((resutl) => {
      this.statesService.viewsStorage.next(currentViewStorage);
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  animal: string;
  columnName: string;
}
