import { ViewModel } from './models/view.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AddViewDialog } from './add-view/add-view.dialog';
import { SearchGlobalService } from './../../../core/services/search-global.service';
import { AliasListModel } from './alias-list.model';
import { ColumnListModel } from './add-states/columns.model';
import { AddColumnsDialog } from './add-states/add-column.dialog';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { DialogContentExampleDialog } from './../activation-key/product-card/product-card.component';
import { MatTableDataSource } from '@angular/material/table';
import { States } from './states.model';
import { StatesService } from './states.service';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EditColumnDialog } from './edit-column/edit-column.dialog';
import { UserDashboardService } from '../../services/user-dashboard.service';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { StateInputsStorageModel } from './models/state-storage.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from './models/guards/pending-changes.guard';
import { Router } from '@angular/router';
@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss'],
})
export class StatesComponent
  implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate {
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.temporaryStorage.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  displayedColumns: string[] = ['star_state', 'first_name', 'last_name'];
  dataSource = new MatTableDataSource([]);
  extra: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  name: string;
  aliasList: AliasListModel;
  views: ViewModel[];
  currentView: any;
  stateStorageInputs: StateInputsStorageModel[];
  stateStorageInputsLatest: StateInputsStorageModel[];
  contentIsModified: boolean = false;
  temporaryStorage = [];
  isLoading: boolean = true;
  constructor(
    private statesService: StatesService,
    public dialog: MatDialog,
    private router: Router,
    private searchGlobalService: SearchGlobalService,
    private snackBar: MatSnackBar
  ) { }
  temporarySaveHandler(
    scoreId: string,
    propertyName: string,
    newExpert: any,
    userId: string
  ) {
    let newValues = {};
    if (propertyName == 'note') {
      newValues[propertyName] = newExpert.target.value;
    } else {
      newValues[propertyName] = newExpert;
    }

    newValues['score_id'] = scoreId;
    newValues['user_id'] = userId;
    let duplicateIndex;

    for (let index in this.temporaryStorage) {
      if (this.temporaryStorage[index].user_id == scoreId) {
        duplicateIndex = index;
      }
    }

    if (duplicateIndex) {
      this.temporaryStorage[duplicateIndex] = {
        ...this.temporaryStorage[duplicateIndex],
        ...newValues,
      };
    } else {
      this.temporaryStorage.push(newValues);
    }
  }

  dataSaveHandler() {
    this.isLoading = true;
    this.statesService.setAllStateInputsStorage(this.temporaryStorage).subscribe(
      (result) => {
        this.snackBar.open('Saved', '', { duration: 10000 });
        this.temporaryStorage = [];
        this.isLoading = false;
        this.contentIsModified = false;
      },
      (error) => {
        this.snackBar.open('Error', '', { duration: 10000 });
        this.isLoading = false;
      }
    );
  }
  noteChangeDetector() {
    this.contentIsModified = true;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddColumnsDialog, {
      data: {
        name:
          this.currentView == undefined ? 'Default' : this.currentView[0].name,
        extra: this.extra,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.extra = result;
    });
  }

  latestStorageStateHandler() {
    this.isLoading = true;
    if (this.statesService.stateInputsStorageLatest.getValue()) {
      this.dataSource = new MatTableDataSource(
        this.statesService.stateInputsStorageLatest.getValue()
      );
      if (this.searchGlobalService.globalSearchStorage.getValue()) {
        this.dataSource.filter = this.searchGlobalService.globalSearchStorage
          .getValue()
          .trim()
          .toLowerCase();
      }

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    } else {
      this.statesService
        .getAllStateInputsStorageLatest()
        .subscribe((result: StateInputsStorageModel[]) => {
          this.statesService.stateInputsStorageLatest.next(result);
        });
    }
  }
  allStorageStateHandler() {
    this.isLoading = true;
    if (this.statesService.stateInputsStorage.getValue()) {
      this.dataSource = new MatTableDataSource(
        this.statesService.stateInputsStorage.getValue()
      );

    } else {
      this.statesService
        .getAllStateInputsStorage()
        .subscribe((result: StateInputsStorageModel[]) => {
          this.statesService.stateInputsStorage.next(result);
        });
    }
    if (this.searchGlobalService.globalSearchStorage.getValue()) {
      this.dataSource.filter = this.searchGlobalService.globalSearchStorage
        .getValue()
        .trim()
        .toLowerCase();
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }


  ngAfterViewInit() { }
  deleteView() {
    if (
      this.currentView === undefined ||
      this.currentView[0].name === 'Default'
    ) {
      return;
    }
    this.statesService
      .deleteView(this.currentView[0].name)
      .subscribe((result: any) => {
        this.statesService.columnListStorage.next(result.column_list_object);
        this.statesService.viewsStorage.next(result.views);
        this.views = result.views;
        this.currentView = result.views[result.views.length - 1];
        this.updateView(this.currentView.name);
      });
  }

  ngOnInit(): void {
    this.statesService.stateInputsStorageLatest.subscribe(
      (result: StateInputsStorageModel[]) => {
        this.dataSource = new MatTableDataSource(result);
        if (this.searchGlobalService.globalSearchStorage.getValue()) {
          this.dataSource.filter = this.searchGlobalService.globalSearchStorage
            .getValue()
            .trim()
            .toLowerCase();
        }
        this.stateStorageInputsLatest = result;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      }
    );
    this.isLoading = true;

    this.statesService.stateInputsStorage.subscribe(
      (result: StateInputsStorageModel[]) => {
        this.stateStorageInputs = result;
      }
    );
    
    this.statesService.currentViewStorage.subscribe((result) => {
      if (result) {
        this.currentView = result;
        this.updateView(this.currentView.name);
      }
    });
    this.statesService.getAllViews().subscribe((result) => {
      this.statesService.viewsStorage.next(result);
    });
    this.statesService.viewsStorage.subscribe((result) => {
      this.views = result;
    });

    this.statesService.getAliasList().subscribe((result: AliasListModel) => {
      this.statesService.alliasList.next(result);
    });
    this.statesService.alliasList.subscribe((result: AliasListModel) => {
      this.aliasList = result;
    });


    // GET STATES IN DATABASE
    // this.statesService.getStates().subscribe((result: States[]) => {

    this.statesService.getAllStateInputsStorageLatest().subscribe((result: States[]) => {
      this.statesService.statesStorage.next(result);
      this.searchGlobalService.globalSearchStorage.subscribe((result) => {
        if (result) {
          this.dataSource.filter = result.trim().toLowerCase();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;          
        } else {
          this.dataSource = new MatTableDataSource(
            this.statesService.statesStorage.getValue()
          );

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        }
      });
    });


    this.statesService.getColumnList().subscribe((result: ColumnListModel) => {
      this.statesService.columnListStorage.next(result);
    });
    this.statesService.columnListStorage.subscribe((result: any) => {
      let finalResult = [
        'star_state',
        'last_name',
        'first_name',
        'email',
        'company_name',
        'campaign_id',
      ];
      if (result) {
        if (this.currentView === undefined) {
          if (this.views) {
            let targetView = this.views.filter(
              (view) => view.name === 'Default'
            );
            let filteredResult = Object.entries(targetView[0].value);
            filteredResult.forEach((element) => {
              if (element[1]) {
                finalResult.push(element[0]);
              }
            });
          }
        } else {
          let filteredResult = Object.entries(result);
          filteredResult.forEach((element) => {
            if (element[1]) {
              finalResult.push(element[0]);
            }
          });
        }
        this.displayedColumns = finalResult;
      }
    });

    this.statesService.statesStorage.subscribe((result: States[]) => {
      console.log(result);

      this.dataSource = new MatTableDataSource(result);
    });
  }
  updateView(viewName: string) {
    this.currentView = this.views.filter((view: any) => view.name == viewName);
    this.statesService.columnListStorage.next(this.currentView[0].value);
    this.currentView.name = viewName;
  }
  editColumn(columnName: string) {
    const dialogRef = this.dialog.open(EditColumnDialog, {
      data: { columnName: columnName, extra: this.extra },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.extra = result;
    });
  }

  addColumn() {
    const dialogRef = this.dialog.open(AddViewDialog, {
      data: { columnName: null, extra: this.extra },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.extra = result;
    });
  }



  export() {
    const readyToExport = this.statesService.statesStorage.getValue();
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.dataSource._filterData(readyToExport)); // create a worksheet with data
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book

 // Edit the first row based on the comparison with the array of new names
 const firstRowIndex = 0;
 const header = XLSX.utils.sheet_to_json(workSheet, { header: 1 })[firstRowIndex] as Array<string> ; // Assuming the first row contains the header
 for (let colIndex = 0; colIndex < header.length; colIndex++) {
   const columnName = XLSX.utils.encode_col(colIndex);
   if (header[colIndex] in this.aliasList) {
     const cellRef = `${columnName}${firstRowIndex + 1}`;
     workSheet[cellRef] = { v: this.aliasList[header[colIndex]], t: 's' }; // Modify the cell value
   }else{
     const cellRef = `${columnName}${firstRowIndex + 1}`;
     workSheet[cellRef] = { v: header[colIndex], t: 's' }; 
   }
 }
    XLSX.writeFile(workBook, 'temp.xlsx'); // initiate a file download in browser
}

  

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month') {
      const date = cellDate.getDate();
      return date === 1 || date === 20 ? 'example-custom-date-class' : '';
    }
    return '';
  };

  rowClickHandler(event: any, row: any) {
    if (event.ctrlKey || event.metaKey || event.ctrlKey
      || event.keyCode == 91 || event.keyCode == 224
    ) {
      this.router.navigate([`dashboard/patients/edit/${row.patient_id}`]);
    }
  }

  ngOnDestroy(): void { }
}
