import { Component, OnInit } from '@angular/core';
import { ActivationKeyService } from 'src/app/dashboard/services/activation-key.service';

@Component({
  selector: 'app-order-result',
  templateUrl: './order-result.component.html',
  styleUrls: ['./order-result.component.scss'],
})
export class OrderResultComponent implements OnInit {
  ordered_package_data = null;
  constructor(private activationKeyService: ActivationKeyService) {}

  ngOnInit(): void {
    this.activationKeyService.ordered_package.subscribe((data) => {
      this.ordered_package_data = data.activationKeys;
    });
  }
}
