import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PractitionersComponent } from './dashboard/pages/practitioners/practitioners.component';
import { EditPractitionerComponent } from './dashboard/pages/edit-practitioner/edit-practitioner.component';
import { PilejeAuthGuard } from './auth/services/pileje-auth-guard.service';
import { AuthGuard } from './auth/services/auth-guard.service';
const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: '',
    redirectTo: 'connexion',
    pathMatch: 'full',
  },
  {
    path: 'pileje/practitioners',
    component: PractitionersComponent,
    canActivate: [PilejeAuthGuard],
  },
  {
    path: 'pileje/practitioners/edit/:practitionerId',
    component: EditPractitionerComponent,
    canActivate: [PilejeAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
