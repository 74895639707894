<ng-container *ngIf="$practitioner | async as practitioner">
  <ng-container *ngIf="!isPilejeRoute">
    <ng-container
      *ngIf="practitioner.enabled; then disableButton; else enableButton"
    ></ng-container>
    <ng-template #enableButton>
      <button
        mat-raised-button
        color="green"
        (click)="handleSwitchStatus(true)"
      >
        Activer le compte
      </button>
    </ng-template>
    <ng-template #disableButton>
      <button
        mat-raised-button
        color="warn"
        (click)="handleSwitchStatus(false)"
      >
        Désactiver le compte
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="isPilejeRoute">
    <button
      mat-raised-button
      class="button reject"
      [disabled]="practitioner.enabled === false"
      (click)="handleRegistration(false)"
    >
      {{ practitioner.enabled === false ? 'Refusé' : "Refuser l'inscription" }}
    </button>

    <button
      mat-raised-button
      class="button accept"
      [disabled]="practitioner.enabled === true"
      (click)="handleRegistration(true)"
    >
      {{ practitioner.enabled === true ? 'Activé' : "Valider l'inscription" }}
    </button>
  </ng-container>
</ng-container>
