import { CampaignModel } from './campaign.model';
import { CampaignService } from './campaign.service';
import { filter, map } from 'rxjs/operators';
import { CompanyService } from './../company/company.service';
import { CompanyModel } from './../company/company.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { observable, throwError } from 'rxjs';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit {
  company: CompanyModel[];
  campaign: CampaignModel[];
  displayedColumns: string[] = ['id', 'name', 'domain'];
  displayedColumnsCampaign: string[] = [
    'name',
    'quota',
    'isDeleted',
    'type',
    'updatedAt',
    'createdAt',
    'keyType',
    'details',
    'id',
  ];
  usersCreatingAccount: number;
  usersFillingQuestionnaire: number;
  usersFillingBloodTestsResults: number;
  usersReadingScores: number;
  usersReadingIndex: number;
  usersReadingRecos: number;
  usersWatchingVideos: number;
  usersBuyingPhytotherapy: number;
  usersBuyingRDVExpert: number;
  usersAnsweringSatisfactionSurvey: number;
  usersAskingActivationCode: number;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
    private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((companyIdObject) => {
      this.companyService.companyStorage.subscribe(
        (companies: CompanyModel[]) => {
          if (companies) {
            let filteredCompany = companies.filter(
              (company) => company.id === companyIdObject.companyId
            );

            this.campaignService
              .getSumActivationKeyByCompany(filteredCompany[0].id)
              .subscribe((result: any) => {
                this.usersCreatingAccount = result;
              });

            this.campaignService
              .getSumOfQuestionerSentByCompany(filteredCompany[0].id)
              .subscribe((result: number) => {
                this.usersFillingQuestionnaire = result;
              });

            this.campaignService
              .getSumOfScoreViewedByCompany(filteredCompany[0].id)
              .subscribe((result: number) => {
                this.usersReadingScores = result;
              });

            this.campaignService
              .getSumOfIndexesViewedByCompany(filteredCompany[0].id)
              .subscribe((result: number) => {
                this.usersReadingIndex = result;
              });

            this.campaignService
              .getSumOfRecosViewedByCompany(filteredCompany[0].id)
              .subscribe((result: number) => {
                this.usersReadingRecos = result;
              });

            this.campaignService
              .getSumOfVideoViewedByCompany(filteredCompany[0].id)
              .subscribe((result: number) => {
                this.usersWatchingVideos = result;
              });

            this.campaignService
              .getSumOfBoughtPhytotherapyByCompany(filteredCompany[0].id)
              .subscribe((result: number) => {
                this.usersBuyingPhytotherapy = result;
              });

            this.campaignService
              .getSumOfUsersBuyingRDVExpertByCompany(filteredCompany[0].id)
              .subscribe((result: number) => {
                this.usersBuyingRDVExpert = result;
              });
            this.campaignService
              .getSumOfUsersAskingAKByCompany(filteredCompany[0].id)
              .subscribe((result: number) => {
                this.usersAskingActivationCode = result;
              });
          }
        },
        (error) => {
          throwError(new Error('Request failed'));
        }
      );

      this.companyService.companyStorage.subscribe(
        (companies: CompanyModel[]) => {
          if (companies) {
            let filteredCompany = companies.filter(
              (company) => company.id === companyIdObject.companyId
            );
            this.company = filteredCompany;
            this.campaignService
              .getCampaignByCompanyId(companyIdObject.companyId)
              .subscribe(
                (campaign: CampaignModel[]) => {
                  if (campaign) {
                    this.campaign = campaign;
                    this.campaignService.campaignStorage.next(this.campaign);
                  }
                },
                (error) => {
                  throwError(new Error('Request failed'));
                }
              );
          }
        },
        (error) => {
          throwError(new Error('Request failed'));
        }
      );
    });
  }

  onNavigate(row: any) {
    this.onShowDetials(row.id);
  }
  onShowDetials(campaignId: any) {
    this.campaignService.campaignStorage.next(this.campaign);
    this.router.navigate(['campaign-details', campaignId]);
  }
}
