import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, NgControl } from '@angular/forms';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RequestDataType } from '../models/request-data-type';
import { ActivationKeyService } from 'src/app/dashboard/services/activation-key.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy {
  package_data_sub: Subscription;
  package_info: any;
  counter = 1;
  companyValidated: boolean = false;
  campaignValidated: boolean = false;
  activationKeyValidated: boolean = false;
  spinnerVisible = false;
  company_id;
  company_name;
  company_domain;
  campaign_name;
  campaign_type;
  campaign_quota;
  campaign_key_type;
  mail_address;
  error_message;
  requestFinished;
  formValidator = false;
  @ViewChild('stepper', { static: false }) private myStepper: MatStepper;
  // name: string;
  // type: string;
  // full: number;
  // treatment: number;
  // consultation: number;
  // box: number;
  constructor(
    private activationKeyService: ActivationKeyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activationKeyService.error_handler.subscribe((data) => {
      this.error_message = data;
    });
    this.package_data_sub = this.activationKeyService.package_data.subscribe(
      (data) => {
        if (!data) {
          this.router.navigate(['activation-key']);
        }
        this.package_info = data;
      }
    );
  }
  onIncrease() {
    this.counter++;
  }
  onDecrease() {
    if (this.counter > 0) this.counter--;
  }
  generate(
    campaignType: string,
    quantity: number,
    packageType: string,
    companyName: string
  ) {
    let package_temp_data: RequestDataType = {
      type: packageType,
      quantity: quantity,
      campaignType: campaignType,

      companyId: companyName,
    };

    this.activationKeyService
      .generatePackage(package_temp_data)
      .subscribe((data) => {
        this.activationKeyService.ordered_package.next(data);
      });
  }
  createCampaign(name, type, quota, company_id, key_type) {
    if (type.value && quota.value && company_id.value && key_type.value) {
      this.spinnerVisible = true;
      const campaign = {
        name: name.value,
        campaignType: type.value,
        quota: quota.value,
        companyId: company_id.value,
        keyType: key_type.value,
      };

      this.activationKeyService.createCampaign(campaign).subscribe(
        (data) => {
          if (data) {
            this.spinnerVisible = false;
            this.campaignValidated = true;
            this.formValidator = false;
            // console.log(data);
          }
        },
        (error) => {
          this.activationKeyService.error_handler.next(error.message);
        }
      );
    } else {
      this.formValidator = true;
    }
  }
  createCompany(companyID: string, companyName: string, domain: string) {
    this.spinnerVisible = true;
    const company = {
      id: companyID,
      name: companyName,
      domain,
    };
    this.activationKeyService.createCompany(company).subscribe(
      (data) => {
        if (data) {
          this.spinnerVisible = false;

          this.companyValidated = true;
          this.campaign_key_type = this.package_info.type;
        }
      },
      (error) => {
        this.activationKeyService.error_handler.next(error.message);
      }
    );
  }

  finalizeRequest(productType, quantity, companyId, mailAddress, campaignType) {
    this.requestFinished = false;
    if (
      productType.value &&
      quantity.value &&
      companyId.value &&
      campaignType.value
    ) {
      this.spinnerVisible = true;
      this.formValidator = false;
      const activationKeyObject = mailAddress.value
        ? {
            type: productType.value,
            quantity: parseInt(quantity.value),
            companyId: companyId.value,
            mailAddress: mailAddress.value,
            campaignType: campaignType.value,
          }
        : {
            type: productType.value,
            quantity: parseInt(quantity.value),
            companyId: companyId.value,

            campaignType: campaignType.value,
          };
      this.activationKeyService.generatePackage(activationKeyObject).subscribe(
        (data) => {
          this.activationKeyService.ordered_package.next(data);
          this.spinnerVisible = false;
          this.activationKeyValidated = true;
        },
        (error) => {
          this.activationKeyService.error_handler.next(error.message);
        }
      );
    } else {
      this.formValidator = true;
    }
  }
  finishRequest() {
    this.spinnerVisible = false;

    this.requestFinished = true;
  }

  ngOnDestroy(): void {
    this.activationKeyService.error_handler.next(null);
  }

  closeErrorBox() {
    this.error_message = null;
    this.spinnerVisible = false;
  }
}
