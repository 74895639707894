import { MatSort } from '@angular/material/sort';
import { throwError } from 'rxjs';
import { CompanyModel } from './company.model';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService } from './company.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SearchGlobalService } from 'src/app/core/services/search-global.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {
  searchText: string;
  displayedColumns: string[] = [
    'id',
    'name',
    'domain',
    'isDeleted',
    'createdAt',
    'details',
  ];
  dataSource: MatTableDataSource<CompanyModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private companyService: CompanyService,
    private router: Router,
    private globalSearchService: SearchGlobalService
  ) {}
  ngOnDestroy(): void {
    this.companyService.searchStorage.next(this.searchText);
  }

  ngOnInit(): void {
    this.companyService.getCompanies().subscribe(
      (result: CompanyModel[]) => {
        this.dataSource = new MatTableDataSource<CompanyModel>(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.companyService.companyStorage.next(result);

        this.companyService.searchStorage.subscribe((result) => {
          if (result) {
            if (this.dataSource) {
              this.searchText = result;
              this.dataSource.filter = result.trim().toLowerCase();
            }
          }
        });
        this.globalSearchService.globalSearchStorage.subscribe((searchText) => {
          if (result) {
            this.applyFilter(searchText);
          } else {
            this.dataSource = new MatTableDataSource<CompanyModel>(result);
          }
        });
      },
      (error) => {
        throwError(new Error('Request failed'));
      }
    );
  }

  applyFilter(event: Event | string) {
    if (typeof event === 'string') {
      this.dataSource.filter = event.trim().toLowerCase();
    } else if (event != null) {
      const filterValue = (event.target as HTMLInputElement).value;
      // this.companyService.searchStorage.next(filterValue);
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  ngAfterViewInit() {}

  onSearch(input: string) {}
  onShowDetials(companyId: string) {
    this.router.navigate(['campaign', companyId]);
  }

  onNavigate(row: any) {
    this.onShowDetials(row.id);
  }
}
