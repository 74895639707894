import { SearchGlobalService } from './../../../core/services/search-global.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportService } from '../../services/export.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  public showToolbarExalis: boolean = false;
  public toolbarText: string = 'Numa Health Pilot Backoffice';
  searchText;
  constructor(
    private authService: AuthService,
    private searchGlobalService: SearchGlobalService,
    private activatedRoute: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly exportService: ExportService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((urlSegments) => {
      const url = urlSegments.map((segment) => segment.path).join('/');
      const isPractitionersRoute = url === 'pileje/practitioners';
      const isEditPractitionerRoute = url.startsWith(
        'pileje/practitioners/edit/'
      );
      this.showToolbarExalis = isPractitionersRoute || isEditPractitionerRoute;
      this.toolbarText = this.showToolbarExalis
        ? 'Exalis Backoffice'
        : 'Numa Health Pilot Backoffice';
    });
    this.searchGlobalService.globalSearchStorage.subscribe((result) => {
      this.searchText = result;
    });
  }
  redirectToExports(): void {
    this.router.navigate(['dashboard/exports-exalis']);
  }
  redirectToPractitionersList(): void {
    this.router.navigate(['pileje/practitioners']);
  }
  handleLogout(): void {
    this.authService.logout();
  }
  onSearch(input: string) {
    this.searchGlobalService.globalSearchStorage.next(input);
  }


  
  downloadKpiDataExalis(): void {
    this.exportService.getDownloadableKpiDataExalis().subscribe(data => {
      const filename = this.generateFilename('Indicateurs de suivi Exalis');
      this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
    }, error => this.handleHttpError(error));
  }
  // FIXME
  //  Title should be obtainable from response header 'Content-Disposition' but is currently always
  //  null... Adding allowed headers in backend didn't solve the issue
  private generateFilename(context: string, period = null): string {
    let formattedDate = '';
    if (!period) {
      const now = new Date();
      formattedDate = `${now.toLocaleDateString('fr-FR')}`;
    } else {
      formattedDate = `${new Date(period.start).toLocaleDateString('fr-FR')}-${new Date(period.end).toLocaleDateString('fr-FR')}`;
    }

    return `[Export Numa] ${context} - ${formattedDate}.csv`;
  }

  private triggerDownload(data, type, filename): void {
    const blob = new Blob([data], { type: type });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.download = filename;
    anchor.href = url;
    anchor.click();
  }

  private handleHttpError(error): void {
    let errorMessage = JSON.parse(error.error);
    errorMessage = errorMessage.message ? errorMessage.message : error.statusText;

    this.snackBar.open(
      `Une erreur est survenue lors de la génération de l'export : ${errorMessage}`,
      '',
      { verticalPosition: 'top', horizontalPosition: 'right', duration: 5000 },
    );
  }
}
