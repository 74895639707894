import { UserStatusModel } from './../pages/user-dashboard/user-status/user.status.model';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Score } from './../../core/models/score.model';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserDashboardService {
  private commercialURL = environment.api.commercialUrl;
  private authURL = environment.api.authUrl;
  usersSortedData = [];
  user_data = new Subject<any>();
  dataIsReady = new Subject<any>();
  dataIsSorted = new Subject<any>();
  storedSortedData = new BehaviorSubject<any>(null);
  completeData: any[] = [];
  userStates = [];
  searchText = '';

  constructor(private http: HttpClient) {}

  retrieveUserData(limit: number, page: number) {
    return this.http.get(
      this.authURL + '/patients' + `?limit=${limit}&page=${page}&enabled=any`
    );
  }
  getAllUsers() {
    return this.http.get(this.authURL + '/patients/all-patients');
  }
  retrieveActivationKeysData() {
    return this.http.get(this.commercialURL + '/activationkeys');
  }

  getAllReport(userId): Observable<Score> {
    return this.http.get<any>(
      this.commercialURL + `/scores/reportbyadmin/${userId}`
    );
  }
  setSortedData(sortedData) {
    this.storedSortedData.next(sortedData);
  }
  getSortedData() {
    return this.storedSortedData;
  }
  retrieveActivationKeysByUserId(userId: string) {
    return this.http.get(this.commercialURL + '/activationkeys/' + userId);
  }
  storeStatus(statusData) {
    return this.http.post(
      this.commercialURL + '/user-activation-key-status',
      statusData
    );
  }
  getStatus(activationKey: string) {
    return this.http.get(
      this.commercialURL + '/user-activation-key-status/' + activationKey
    );
  }
  getStartStatus(score_id: string) {
    return this.http.get(this.commercialURL + '/star-status/' + score_id);
  }
  getStartStatusByUserId(user_id: string) {
    return this.http.get(
      this.commercialURL + '/star-status/user_id/' + user_id
    );
  }
  setStarStatus(info: any) {
    return this.http.post(this.commercialURL + '/star-status/', info);
  }
  getAllState() {
    return this.http.get(this.authURL + '/patients/states');
  }
}
