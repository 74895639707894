<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<table>
  <tr>
    <th>Last Evaluation Date</th>
    <th>ID</th>
    <th>Company Name</th>
    <th>InscriptionDate</th>
    <th>Type</th>
    <th>UpdatedAt</th>
    <th>
      Collection Date
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Notes
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Save
    </th>
  </tr>
  <tr *ngFor="let activationkey of activationKeys">
    <td>{{ lastEvaluationDate }}</td>
    <td>{{ activationkey.id }}</td>
    <td>{{ activationkey.companyName }}</td>
    <td>{{ activationkey.createdAt | date: 'shortDate' }}</td>

    <td>{{ activationkey.type }}</td>
    <td>{{ activationkey.updatedAt | date: 'shortDate' }}</td>

    <app-store-info
      [keyId]="activationkey.id"
      [userId]="user_id"
    ></app-store-info>
  </tr>
</table>
