import { Component, OnInit } from '@angular/core';
import { ActivationKeyService } from '../../services/activation-key.service';
import { ProductType } from './models/product-type.model';
@Component({
  selector: 'app-activation-key',
  templateUrl: './activation-key.component.html',
  styleUrls: ['./activation-key.component.scss'],
})
export class ActivationKeyComponent implements OnInit {
  ordered_package_ready = false;
  // input_data_full = {
  //   full: 1,
  //   treatment: 0,
  //   consultation: 0,
  //   box: 0,
  // };
  quantity;
  error = false;
  saved = false;
  campaign;
  selected_key_campaign;
  campaigns: [];
  mail_address;
  all_product_types: ProductType[] = [
    {
      name: 'Full',
      type: 'full',
      full: 1,
      treatment: 0,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Pack',
      type: 'pack',
      full: 2,
      treatment: 2,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Pack Consult',
      type: 'pack_consult',
      full: 1,
      treatment: 0,
      consultation: 1,
      box: 0,
    },
    {
      name: 'Double Full',
      type: 'double_full',
      full: 2,
      treatment: 0,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Double Full Consult',
      type: 'double_full_consult',
      full: 2,
      treatment: 0,
      consultation: 1,
      box: 0,
    },
    {
      name: 'Phytocure Program',
      type: 'phytocure_program',
      full: 2,
      treatment: 2,
      consultation: 1,
      box: 0,
    },

    {
      name: 'Special Valentine',
      type: 'special_valentine',
      full: 2,
      treatment: 2,
      consultation: 0,
      box: 0,
    },

    {
      name: 'Darwin Full',
      type: 'darwin_full',
      full: 2,
      treatment: 2,
      consultation: 0,
      box: 0,
    },

    {
      name: 'Darwin Double Full',
      type: 'darwin_double_full',
      full: 2,
      treatment: 0,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Darwin Triple Full',
      type: 'darwin_triple_full',
      full: 3,
      treatment: 0,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Darwin Pack Product',
      type: 'darwin_pack_product',
      full: 2,
      treatment: 0,
      consultation: 0,
      box: 4,
    },
    {
      name: 'Special Medef87 Pack',
      type: 'special_medef87_pack_consult',
      full: 2,
      treatment: 2,
      consultation: 0,
      box: 0,
    },
  ];
  // type: string;
  // quantity: number;
  // campaignType: string;
  // companyName: string;

  constructor(private activationKeyService: ActivationKeyService) {}

  ngOnInit(): void {
    this.activationKeyService.ordered_package.subscribe((data) => {
      this.ordered_package_ready = data;
    });

    this.activationKeyService.getAllKeyRequests().subscribe((data: []) => {
      this.campaigns = data;
    });
  }
  generateKey() {}

  saveKeyRquest() {
    let keyRequest = {
      mailAddress: this.mail_address ? this.mail_address : null,
      quantity: this.quantity ? this.quantity : null,
      campaign: this.selected_key_campaign
        ? this.selected_key_campaign.id
        : null,
    };

    if (this.mail_address && this.quantity && this.selected_key_campaign) {
      this.activationKeyService.setKeyRequest(keyRequest).subscribe((data) => {
        this.saved = true;
      });
      this.error = false;
    } else {
      this.error = true;
    }
  }
}
