import { Component, OnInit } from '@angular/core';
import { PractitionerService } from '../../services/practitioner.service';
import {
  Practitioner,
  PractitionerPaginationDto,
} from '../../../core/models/practitioner.model';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SearchGlobalService } from 'src/app/core/services/search-global.service';

@Component({
  selector: 'app-practitioners',
  templateUrl: './practitioners.component.html',
  styleUrls: ['./practitioners.component.scss'],
})
export class PractitionersComponent implements OnInit {
  public practitionerPagination: PractitionerPaginationDto;
  public practitioners: MatTableDataSource<Practitioner>;
  public showToolbar: boolean = false;
  private isPilejeRoute: boolean = false;

  constructor(
    private practitionerService: PractitionerService,
    private router: Router,
    private globalSearchService: SearchGlobalService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((urlSegments) => {
      const url = urlSegments.map((segment) => segment.path).join('/');
      this.showToolbar = url === 'pileje/practitioners';
      this.isPilejeRoute = this.showToolbar;
    });

    let page;
    // this.fetchPage();
    if (this.isPilejeRoute) {
      this.practitionerService
        .getPilejePractitioner(page, 9999)
        .subscribe((practitionerPagination) => {
          this.handlePractitionerData(practitionerPagination);
        });
    } else {
      this.practitionerService
        .getAll(page, 9999)
        .subscribe((practitionerPagination) => {
          this.handlePractitionerData(practitionerPagination);
        });
    }
  }

  private handlePractitionerData(
    practitionerPagination: PractitionerPaginationDto
  ): void {
    this.practitionerPagination = practitionerPagination;
    this.practitioners = new MatTableDataSource<Practitioner>(
      practitionerPagination.items
    );
    this.globalSearchService.globalSearchStorage.subscribe((result) => {
      if (result) {
        this.applyFilter(result);
      } else {
        this.practitioners = new MatTableDataSource<Practitioner>(
          practitionerPagination.items
        );
      }
    });
  }

  // fetchPage(page?: number): void {
  //   this.practitionerService
  //     .getAll(page, 9999)
  //     .subscribe((practitionerPagination) => {
  //       this.practitionerPagination = practitionerPagination;
  //       this.practitioners = practitionerPagination.items;
  //     });
  // }

  handlePage(pageEvent: PageEvent): void {
    // this.fetchPage(pageEvent.pageIndex);
  }

  handleRowClick(practitioner: Practitioner): void {
    const routePrefix = this.isPilejeRoute ? 'pileje' : 'dashboard';
    this.router.navigate([
      routePrefix,
      'practitioners',
      'edit',
      practitioner.id,
    ]);
  }

  applyFilter(event: Event | string) {
    if (typeof event === 'string') {
      if (this.practitioners) {
        this.practitioners.filter = event.trim().toLowerCase();
      }
    }
  }
}
