import { Score } from './../../../core/models/score.model';
import { Component, OnInit } from '@angular/core';
import { map, switchMap, switchMapTo, take } from 'rxjs/operators';
import { merge, throwError, zip } from 'rxjs';
import { PatientService } from '../../services/patient.service';
import {
  Patient,
  PatientCreditDto,
  PatientPlanDto,
  UpdatePatientDto,
} from '../../../core/models/patient.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatchEmptyError } from '../../../core/errors/patch-empty.error';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

interface patientIdModel {
  patientId: string;
}
@Component({
  selector: 'app-edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['./edit-patient.component.scss'],
})
export class EditPatientComponent implements OnInit {
  public loaded = false;
  public patient: Patient;
  public patientCredit: PatientCreditDto;
  public patientPlans: PatientPlanDto[];
  public requestRegister = false;
  displayedColumnsPlans: string[] = ['TYPE', 'PRICE', 'START', 'END', 'CANCEL', 'ACTIVE'];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataCredits: any = [];
  dataPlans: any = [];
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchPatient();
    this.route.params.subscribe((patientIdData: patientIdModel) => {
      this.patientService
        .getScoresByPatientId(patientIdData.patientId)
        .subscribe((scores: Score) => {
          this.dataCredits = scores;
        });
      this.patientService
        .getPlans(patientIdData.patientId)
        .subscribe((plans: PatientPlanDto[]) => {
          this.dataPlans = plans;
          this.dataPlans.map((plan) => {
            switch (plan.type) {
              case 'SUBSCRIPTION-1-YEAR':
                plan.type = 'Annuel';
                break;

              case 'SUBSCRIPTION-MONTHLY':	
                plan.type = 'Mensuel';
                break;

              default:
                break;
            }
          });
        });
    });
  }

  fetchPatient(): void {
    this.loaded = false;
    this.route.paramMap
      .pipe(
        take(1),
        map((paramMap) => {
          if (paramMap.get('patientId')) {
            return paramMap.get('patientId');
          }
          throwError('No id provide');
        }),
        switchMap((patientId: string) => {
          return zip(
            this.patientService.getById(patientId),
            this.patientService.getCredits(patientId),
          ).pipe(take(1));
        })
      )
      .subscribe((result) => {
        const [patient, patientCredit] = result;
        this.patient = patient;
        this.patientCredit = patientCredit;
        this.patientService.getPlans(patient.userId).subscribe((result) => {
          this.patientPlans = result;

           this.patientPlans.map((plan) => {
            switch (plan.type) {
              case 'SUBSCRIPTION-1-YEAR':
                plan.type = 'Annuel';
                break;

              case 'SUBSCRIPTION-MONTHLY':	
                plan.type = 'Mensuel';
                break;

              default:
                break;
            }
          });})
        this.loaded = true;
      });
  }

  get isPatientRecord(): boolean {
    if (!this.patient) {
      return false;
    }
    return !this.patient.enabled && !!this.patient.patientCode;
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, '', { duration: 10000 });
  }

  handleSuccess(message: string): void {
    this.openSnackBar(message);
    this.fetchPatient();
  }

  handleError(message: string): void {
    this.openSnackBar(message);
  }

  handleRegisterRequest(): void {
    this.requestRegister = true;
  }

  handleRegisterPatient(values: UpdatePatientDto): void {
    this.patientService.registerByPatientCode(this.patient, values).subscribe(
      () => {
        this.handleSuccess('Enregistrement effectué');
      },
      (error) => {
        if (error.status && error.status === 500) {
          this.handleError(
            `Impossible de créer le patient. Vérifier que le mail ou le N° de sécurité sociale n'est pas déjà attribué`
          );
        }
      }
    );
  }

  handleEditPatient(values: UpdatePatientDto): void {
    this.patientService.update(this.patient, values).subscribe(
      () => {
        this.handleSuccess('Modification effectuée');
      },
      (error) => {
        if (error instanceof PatchEmptyError) {
          this.handleError(error.message);
        }
      }
    );
  }
}
