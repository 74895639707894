<div>
  <b> <h1>Bienvenue dans le back office de Health Pilot</h1></b>
  <br />
  <table>
    <tr>
      <td>
        <h2>Patients</h2>
        <img
          src="../../../../assets/images/patient.png"
          routerLink="/dashboard/patients"
        />
      </td>
      <td>
        <h2>Praticiens</h2>
        <img
          src="../../../../assets/images/pro.png"
          routerLink="/dashboard/practitioners"
        />
      </td>
      <td>
        <h2>Demandes de Synthèse</h2>
        <img
          src="../../../../assets/images/summary.jpg"
          routerLink="/dashboard/synthesis-orders"
        />
      </td>
    </tr>

    <tr>
      <td>
        <h2>Exports</h2>
        <img
          src="../../../../assets/images/export.png"
          routerLink="/dashboard/exports"
        />
      </td>
      <td>
        <h2>Clé d'Activation</h2>
        <img
          src="../../../../assets/images/ak.png"
          routerLink="/dashboard/activation-key"
        />
      </td>
      <td>
        <h2>Tableau de bord utilisateur</h2>
        <img
          src="../../../../assets/images/cms.png"
          routerLink="/dashboard/user-dashboard"
        />
      </td>
    </tr>
  </table>
</div>
