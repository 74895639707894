<user-dashboard (messageEvent)="greet($event)"></user-dashboard>
<div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="flex_container">
    <!-- <fieldset>
    <legend>Filtres</legend>
    <div class="toggler">
      <table class="toggler-table">
        <tr>
          <td><span>Marqué</span></td>
          <td>
            <label class="switch">
              <input type="checkbox" (click)="onlyMarkedHandller()" />

              <span class="slider round"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td><span>Activé</span></td>
          <td>
            <label class="switch">
              <input type="checkbox" (click)="onlyActivatedHandaller()" />
              <span class="slider round"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td><span> Compte Activé</span></td>
          <td>
            <label class="switch">
              <input type="checkbox" (click)="onlyEnabledHanaller()" />
              <span class="slider round"></span>
            </label>
          </td>
        </tr>
      </table>
    </div>
  </fieldset> -->
  </div>
  <div class="partial-search">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> </mat-panel-title>
          <mat-panel-description>
            <mat-icon>search</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="search">
          <span class="material-icons search-icon"> search </span>
          <input
            class="search-text"
            type="text"
            placeholder="e.g:abc@xyz.com or PNXYZ..."
            [(ngModel)]="searchText"
            (ngModelChange)="onSearch($event)"
          />
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div *ngIf="noDataFound">
    <h3>No Data Found!</h3>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
    class="mat-elevation-z8"
  >
    <!-- Position Column -->
    <ng-container matColumnDef="mark">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by number"
      >
        Marque
      </th>
      <td mat-cell *matCellDef="let element">
        <app-star [userId]="element.user_id" [star]="element.star"></app-star>
      </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="lastName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by number"
      >
        Nom
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.last_name }}
      </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="firstName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by name"
      >
        Prénom
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activationKey">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Clé d'activation
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element.id
            ? element.id.length < 10
              ? element.id
              : (element.id | slice: 0:9) + '...'
            : 'N/A'
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="etc">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Date de création
      </th>
      <td mat-cell *matCellDef="let element" class="test">
        {{ element.created_at | date }}
        <!-- <app-store-info
        *ngIf="element.ak_id"
        [keyId]="element.ak_id ? element.ak_id : null"
        [userId]="element.user_id"
        [note]="element.note"
        [collectionDate]="element.collectionDate"
      ></app-store-info> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Entreprise
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.company_name ? element.company_name : '' }}
        {{ element.company_id ? '(' + element.company_id + ')' : 'N/A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.type ? element.type : 'N/A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Quantité
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.quantity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Type de campagne
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.campaign_type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Email
      </th>
      <td mat-cell *matCellDef="let element">{{ element.mail_address }}</td>
    </ng-container>

    <ng-container matColumnDef="activated">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by weight"
      >
        Clé
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="{{ element.is_activated ? 'enable' : 'disable' }}"
      >
        <!-- {{ element.is_activated }} -->
        <img
          src="../../../../assets/images/complete.png"
          width="30"
          *ngIf="element.is_activated"
        />
        <img
          src="../../../../assets/images/disable.png"
          width="30"
          *ngIf="!element.is_activated"
        />
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="enabled">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by weight"
    >
      Compte
    </th>
    <td mat-cell *matCellDef="let element">
      <img
        src="../../../../assets/images/complete.png"
        width="30"
        *ngIf="element.enabled"
      />
      <img
        src="../../../../assets/images/disable.png"
        width="30"
        *ngIf="!element.enabled"
      />
    </td>
  </ng-container> -->

    <!-- <ng-container matColumnDef="lastEvaluationDate">
  <th
    mat-header-cell
    *matHeaderCellDef
    mat-sort-header
    sortActionDescription="Sort by weight"
  >
    Last Evaluation Date
  </th>
  <td mat-cell *matCellDef="let element">
    {{ element.lastDate }}
    {{
      element.lastDate > 1
        ? 'days'
        : element.lastDate == 1 || element.lastDate == 0
        ? 'day'
        : 'N/A'
    }}
  </td>
</ng-container> -->

    <tr
      class="mat-header-row"
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="row-style"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>
  <mat-paginator
    [length]="totalRows"
    [pageSize]="10"
    [pageSizeOptions]="[10, 30, 50, 80, 100]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
    >
  </mat-paginator>
</div>
