import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PatientService } from '../../services/patient.service';
import { SearchGlobalService } from 'src/app/core/services/search-global.service';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @Output() public rowClick = new EventEmitter<Patient>();

  public displayedColumns: string[] = [
    'firstName',
    'lastName',
    'gender',
    'email',
    'socialNumber',
    'enabled',
    'createdAt',
  ];

  dataSource = new MatTableDataSource([]);

  constructor(
    private patientService: PatientService,
    private searchGlobalService: SearchGlobalService
  ) {}

  ngOnInit(): void {
    this.patientService.patientList.subscribe((result: Patient[]) => {
      this.dataSource = new MatTableDataSource(result);

      if (this.searchGlobalService.globalSearchStorage.getValue()) {
        this.dataSource.filter = this.searchGlobalService.globalSearchStorage
          .getValue()
          .trim()
          .toLowerCase();
      }
      this.dataSource.sort = this.sort;
    });
  }

  handleRowClick(row: Patient): void {
    this.rowClick.emit(row);
  }
}
