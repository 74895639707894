import { UserDashboardService } from './../../../services/user-dashboard.service';
import { Component, Input, OnInit } from '@angular/core';
import { Data } from '@angular/router';

export interface ActivationKey {
  companyName: string;
  createdAt: Date;
  id: string;
  isActivated: boolean;
  type: string;
  updatedAt: Data;
}
@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss'],
})
export class UserStatusComponent implements OnInit {
  @Input('userId') user_id: string;
  activationKeys: ActivationKey[] = [];
  notes: string = '';
  loading: boolean = true;
  collectionDate: any = '';
  lastEvaluationDate: any = 'Undefined';
  constructor(private userDashboardService: UserDashboardService) {}

  ngOnInit(): void {}

  getLastDate(userId: string) {
    return this.userDashboardService.getAllReport(userId);
  }
  getDateDiff(lastDate) {
    let date1 = Date.parse(lastDate);
    let date2 = Date.now();
    let Difference_In_Time = date2 - date1;

    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Math.floor(Difference_In_Days);
  }
}
