import { StateInputsStorageModel } from './models/state-storage.model';
import { defaultAlias } from './default-alias';
import { AliasListModel } from './alias-list.model';
import { ColumnListModel } from './add-states/columns.model';
import { States } from './states.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StatesService {
  private authUrl = environment.api.authUrl;
  private commercialUrl = environment.api.commercialUrl;
  statesStorage = new BehaviorSubject<States[]>(null);
  searchStorage = new BehaviorSubject<string>(null);
  columnListStorage = new BehaviorSubject<ColumnListModel>(null);
  viewsStorage = new BehaviorSubject<any>(null);
  alliasList = new BehaviorSubject<AliasListModel>(defaultAlias);
  currentViewStorage = new BehaviorSubject<any>(null);
  stateInputsStorage = new BehaviorSubject<StateInputsStorageModel[]>(null);
  stateInputsStorageLatest = new BehaviorSubject<StateInputsStorageModel[]>(
    null
  );
  constructor(private http: HttpClient) {}

  getStates() {
    return this.http.get(this.authUrl + '/patients/statesv2');
  }
  setAlias(current: string, input: string) {
    let currentAlias = this.alliasList.getValue();
  }
  getColumnList() {
    return this.http.get(this.commercialUrl + '/user-state/column-list');
  }
  setColumnList(columnListObject: any) {
    return this.http.post(
      this.commercialUrl + '/user-state/column-list',
      columnListObject
    );
  }
  getAliasList() {
    return this.http.get(this.commercialUrl + '/user-state/column-list/alias');
  }

  saveAlias(aliasListObject: AliasListModel) {
    return this.http.post(
      this.commercialUrl + '/user-state/column-list/alias',
      aliasListObject
    );
  }

  getAllViews() {
    return this.http.get(this.commercialUrl + '/user-state/column-list/views');
  }

  saveView(view: any) {
    return this.http.post(
      this.commercialUrl + '/user-state/column-list/views',
      view
    );
  }
  deleteView(viewNmae: string) {
    return this.http.delete(
      this.commercialUrl + '/user-state/column-list/views/' + viewNmae
    );
  }

  getAllStateInputsStorage() {
    return this.http.get(this.authUrl + '/patients/statesv2');
  }
  getAllStateInputsStorageLatest() {
    return this.http.get(this.authUrl + '/patients/statesv2/storage/latest');
  }
  setAllStateInputsStorage(stateStorage: any) {
    return this.http.post(
      this.authUrl + '/patients/statesv2/storage',
      stateStorage
    );
  }
}
