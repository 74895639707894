import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserDashboardService } from './../../services/user-dashboard.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  AfterViewChecked,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { last } from 'rxjs/operators';
import { Subscription } from 'rxjs';

export interface User {
  lastName: string;
  firstName: string;
  email: string;
  key: Object;
}

export class userStateModel {
  first_name: string;
  last_name: string;
  user_id: string;
  appointment_set: Date;
  first_visit: Date;
  last_visit: Date;
  activation_key: Date;
  bordereau_downloaded: Date;
  questionnaire_sent: Date;
  questionnaire_domains_number: Date;
  data_stress: Date;
  data_clinical: Date;
  data_analysis: Date;
  score_generated: Date;
  score_viewed: Date;
  video_viewed: Date;
  pdf_downloaded: Date;
  phyto_clicked: Date;
  phyto_ordered: Date;
  indexes_viewed: Date;
  reco_viewed: Date;
  reco_clicked: Date;
  reco_checked: Date;
  reco_checked_number: Date;
  reco_commited: Date;
  reco_notified: Date;
}
/**
 * @title Table with sorting
 */
@Component({
  selector: 'user-dashboard',
  styleUrls: ['user-dashboard.component.scss'],
  templateUrl: 'user-dashboard.component.html',
})
export class UserDashboardComponent implements OnInit, OnDestroy {
  @Input('searchText') searchText;
  ELEMENT_DATA: User[] = [];
  showStates = false;
  displayedColumns: string[] = [
    'mark',
    'last_name',
    'first_name',
    'email',
    'lastDate',
    'is_activated',
    'enabled',
    'activationKey',
    'company',
    'type',
    'createdAt',
    'etc',
  ];
  userStateSub: Subscription;
  lastEvaluationDate: any = 'Undefined';
  dataSource: any;
  noDataFound: boolean = false;
  tableData: any[] = [];
  dataToSave = {};
  onlyMarked = false;
  onlyActivated = false;
  onlyEnabled = false;
  loading = true;
  userDataSub: Subscription;
  activationKeysDataSub: Subscription;
  dataIsReadySub: Subscription;
  dataIsSortedSub: Subscription;
  loadStoredDataSub: Subscription;
  totalRows: number;
  totalRowsSetup: number;
  showDashboard = true;

  states = false;
  action = false;
  evaluation = false;
  setup = false;
  viewMode = false;
  dataSourceStates: MatTableDataSource<userStateModel>;
  dataSourceAction: MatTableDataSource<userStateModel>;
  dataSourceEvaluation: MatTableDataSource<userStateModel>;
  dataSourceStats: MatTableDataSource<userStateModel>;

  @Output() greetEvent = new EventEmitter<string>();
  @ViewChild('mainPaginator') paginator: MatPaginator;
  @ViewChild('mainSorter') sort: MatSort;

  // @ViewChild(MatSort) sortStates: MatSort;
  @ViewChild('stateSorter') sortStates: MatSort;
  @ViewChild('statePaginator') paginatorStates: MatPaginator;

  @ViewChild('evaluationSorter') sortEvaluation: MatSort;
  @ViewChild('evaluationPaginator') paginatorEvaluation: MatPaginator;
  @ViewChild('actionSorter') sortAction: MatSort;
  @ViewChild('actionPaginator') paginatorAction: MatPaginator;
  @ViewChild('statsSorter') sortStats: MatSort;
  @ViewChild('statsPaginator') paginatorStats: MatPaginator;
  @Output() messageEvent = new EventEmitter<string>();

  constructor(
    private _snackBar: MatSnackBar,
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private userDashboardService: UserDashboardService
  ) {}
  @Output() setupEventHandller = new EventEmitter();

  displayedColumnsForState: string[] = [
    'first_name',
    'last_name',
    'appointment_set',
    'first_visit',
    'last_visit',
    'activation_key',
    'bordereau_downloaded',
    'questionnaire_sent',
    'questionnaire_domains_number',
    'data_stress',
    'data_clinical',
    'data_analysis',
    'score_generated',
    'score_viewed',
    'video_viewed',
    'pdf_downloaded',
    'phyto_clicked',
    'phyto_ordered',
    'indexes_viewed',
    'reco_viewed',
    'reco_clicked',
    'reco_checked',
    'reco_checked_number',
    'reco_commited',
    'reco_notified',
  ];

  displayedColumnsSetup: string[] = [
    'first_name',
    'last_name',
    'first_visit',
    'last_visit',
    'activation_key',
    'questionnaire_sent',
    'score_generated',
  ];
  displayedColumnsEvaluation: string[] = [
    'first_name',
    'last_name',
    'last_visit',
    'questionnaire_sent',
    'questionnaire_domains_number',
    'data_stress',
    'data_clinical',
    'data_analysis',
    'score_generated',
    'score_viewed',
  ];
  displayedColumnsAction: string[] = [
    'first_name',
    'last_name',
    'last_visit',
    'score_viewed',
    'video_viewed',
    'pdf_downloaded',
    'phyto_clicked',
    'indexes_viewed',
    'reco_viewed',
  ];

  displayedColumnsStates: string[] = [
    'first_name',
    'last_name',
    'last_visit',
    'score_generated',
    'questionnaire_domains_number',
    'appointment_set',
    'phyto_ordered',
    'reco_checked_number',
    'reco_commited',
    'reco_notified',
  ];

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  ngOnInit(): void {
    this.userStateSub = this.userDashboardService.getAllState().subscribe(
      (result: userStateModel[]) => {
        this.userDashboardService.userStates = result;

        this.totalRowsSetup = result.length;
        this.dataSourceStates = new MatTableDataSource(Object.values(result));
        this.dataSourceStates.sort = this.sortStates;
        this.dataSourceStates.paginator = this.paginatorStates;

        this.dataSourceAction = new MatTableDataSource(Object.values(result));
        this.dataSourceAction.sort = this.sortAction;
        this.dataSourceAction.paginator = this.paginatorAction;

        this.dataSourceEvaluation = new MatTableDataSource(
          Object.values(result)
        );
        this.dataSourceEvaluation.sort = this.sortEvaluation;
        this.dataSourceEvaluation.paginator = this.paginatorEvaluation;

        this.dataSourceStats = new MatTableDataSource(Object.values(result));
        this.dataSourceStats.sort = this.sortStats;
        this.dataSourceStats.paginator = this.paginatorStats;
      },
      (error) => {},
      () => {
        this.userDataSub = this.userDashboardService.getAllUsers().subscribe(
          (usersData: any) => {
            this.userDashboardService.completeData = usersData;

            this.totalRows = this.userDashboardService.completeData.length;

            this.dataSource = new MatTableDataSource(
              Object.values(this.userDashboardService.completeData)
            );
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            this.loading = false;
          },
          (error) => {},
          () => {
            if (this.userDashboardService.searchText) {
              this.searchText = this.userDashboardService.searchText;
              this.onSearch(this.userDashboardService.searchText);
            }
          }
        );
      }
    );
  }

  onlyMarkedHandller() {
    this.onlyMarked = !this.onlyMarked;

    if (this.onlyMarked && this.onlyActivated && this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.is_activated && data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyMarked && this.onlyActivated) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyMarked && this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyActivated && this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.enabled && data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyActivated) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else {
      this.dataSource = new MatTableDataSource(
        Object.values(this.userDashboardService.completeData)
      );
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  onlyEnabledHanaller() {
    this.onlyEnabled = !this.onlyEnabled;

    if (this.onlyMarked && this.onlyActivated && this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.is_activated && data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled && this.onlyActivated) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.enabled && data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyMarked && this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyActivated && this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyActivated) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else {
      this.dataSource = new MatTableDataSource(
        Object.values(this.userDashboardService.completeData)
      );
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
  onlyActivatedHandaller() {
    this.onlyActivated = !this.onlyActivated;

    if (this.onlyMarked && this.onlyActivated && this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.is_activated && data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled && this.onlyActivated) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.enabled && data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled && this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyActivated && this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star && data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.star;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.enabled;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyActivated) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          return data.is_activated;
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else {
      this.dataSource = new MatTableDataSource(
        Object.values(this.userDashboardService.completeData)
      );
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
  ngOnDestroy(): void {
    this.loading = true;
    this.userStateSub.unsubscribe();
    this.userDataSub.unsubscribe();
  }
  onSearch(param: string) {
    this.userDashboardService.searchText = param;
    this.applyFilter(param);
    this.messageEvent.emit(param);
    if (this.onlyMarked && this.onlyActivated && this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          if (data.ak_id) {
            if (data.created_at) {
            }
          }
          return (
            data.enabled &&
            data.is_activated &&
            data.star &&
            (data.first_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.last_name
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.email
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id ? data.ak_id : '*%$#9999#####')
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.name
                  ? data.name
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.company_id
                  ? data.company_id
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.type
                  ? data.type
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.created_at
                  ? data.created_at.toString()
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0)
          );
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];
        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled && this.onlyActivated) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          if (data.ak_id) {
            if (data.created_at) {
            }
          }
          return (
            data.enabled &&
            data.is_activated &&
            (data.first_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.last_name
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.email
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id ? data.ak_id : '*%$#9999#####')
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.name
                  ? data.name
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.company_id
                  ? data.company_id
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.type
                  ? data.type
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.created_at
                  ? data.created_at.toString()
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0)
          );
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];
        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled && this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          if (data.ak_id) {
            if (data.created_at) {
            }
          }
          return (
            data.enabled &&
            data.star &&
            (data.first_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.last_name
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.email
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id ? data.ak_id : '*%$#9999#####')
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.name
                  ? data.name
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.company_id
                  ? data.company_id
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.type
                  ? data.type
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.created_at
                  ? data.created_at.toString()
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0)
          );
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];

        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyActivated && this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          if (data.ak_id) {
            if (data.created_at) {
            }
          }
          return (
            data.star &&
            data.is_activated &&
            (data.first_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.last_name
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.email
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id ? data.ak_id : '*%$#9999#####')
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.name
                  ? data.name
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.company_id
                  ? data.company_id
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.type
                  ? data.type
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.created_at
                  ? data.created_at.toString()
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0)
          );
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];
        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyMarked) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          if (data.ak_id) {
            if (data.created_at) {
            }
          }
          return (
            data.star &&
            (data.first_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.last_name
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.email
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id ? data.ak_id : '*%$#9999#####')
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.name
                  ? data.name
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.company_id
                  ? data.company_id
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.type
                  ? data.type
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.created_at
                  ? data.created_at.toString()
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0)
          );
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];
        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyEnabled) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          if (data.ak_id) {
            if (data.created_at) {
            }
          }
          return (
            data.enabled &&
            (data.first_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.last_name
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.email
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id ? data.ak_id : '*%$#9999#####')
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.name
                  ? data.name
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.company_id
                  ? data.company_id
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.type
                  ? data.type
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.created_at
                  ? data.created_at.toString()
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0)
          );
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];
        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.onlyActivated) {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          if (data.ak_id) {
            if (data.created_at) {
            }
          }
          return (
            data.is_activated &&
            (data.first_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.last_name
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              data.email
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id ? data.ak_id : '*%$#9999#####')
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.name
                  ? data.name
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.company_id
                  ? data.company_id
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.type
                  ? data.type
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0 ||
              (data.ak_id
                ? data.created_at
                  ? data.created_at.toString()
                  : '*%$#9999#####'
                : '*%$#9999#####'
              )
                .toLowerCase()
                .indexOf(param.toString().toLowerCase()) >= 0)
          );
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];
        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    } else {
      let filterdData = this.userDashboardService.completeData.filter(
        (data) => {
          if (data.ak_id) {
            if (data.created_at) {
            }
          }
          return (
            data.first_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
            data.last_name
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
            data.email.toLowerCase().indexOf(param.toString().toLowerCase()) >=
              0 ||
            (data.ak_id ? data.ak_id : '*%$#9999#####')
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
            (data.ak_id
              ? data.name
                ? data.name
                : '*%$#9999#####'
              : '*%$#9999#####'
            )
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
            (data.ak_id
              ? data.company_id
                ? data.company_id
                : '*%$#9999#####'
              : '*%$#9999#####'
            )
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
            (data.ak_id
              ? data.type
                ? data.type
                : '*%$#9999#####'
              : '*%$#9999#####'
            )
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0 ||
            (data.ak_id
              ? data.created_at
                ? data.created_at.toString()
                : '*%$#9999#####'
              : '*%$#9999#####'
            )
              .toLowerCase()
              .indexOf(param.toString().toLowerCase()) >= 0
          );
        }
      );
      if (filterdData.length > 0) {
        this.dataSource = new MatTableDataSource(Object.values(filterdData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.noDataFound = false;
      } else {
        let test = [
          {
            user_id: 'N/A',
            first_name: 'N/A',
            phone_number: 'N/A',
            enabled: 'N/A',
            last_name: 'N/A',
            email: 'N/A',
            ak_id: 'N/A',
            company_name: 'N/A',
            type: 'N/A',
            created_at: null,
            is_activated: 'N/A',
            star: 'N/A',
            collectionDate: 'N/A',
            note: 'N/A',
          },
        ];
        this.dataSource = new MatTableDataSource(Object.values(test));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }
  }

  getLastDate(userId: string) {
    return this.userDashboardService.getAllReport(userId);
  }
  getDateDiff(lastDate: any) {
    let date1 = Date.parse(lastDate);
    let date2 = Date.now();
    let Difference_In_Time = date2 - date1;
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Math.floor(Difference_In_Days);
  }

  rowClickHandller(event: any, row: any) {
    if (
      event.ctrlKey ||
      event.metaKey ||
      event.ctrlKey ||
      event.keyCode == 91 ||
      event.keyCode == 224
    ) {
      let route = `dashboard/patients/edit/${row.patient_id}`;
      this.router.navigate([route]);
    }
  }

  openSnackBar() {
    this._snackBar.open('Copied to clipboard', 'Close', { duration: 1000 });
  }

  clientHandler() {
    this.showDashboard = true;
    this.showStates = !this.showStates;
    this.action = false;
    this.states = false;
    this.setup = false;
    this.showStates = false;
  }

  statesHandller() {
    this.showStates = true;
  }

  setupHandler() {
    this.setup = true;
    this.evaluation = false;
    this.action = false;
    this.states = false;
    this.showStates = true;
    this.showDashboard = false;
  }
  evaluationHandler() {
    this.showDashboard = false;
    this.evaluation = true;
    this.action = false;
    this.states = false;
    this.setup = false;
    this.showStates = true;
  }
  actionHandler() {
    this.showDashboard = false;
    this.action = true;
    this.states = false;
    this.evaluation = false;
    this.setup = false;
    this.showStates = true;
  }
  statesHandler() {
    this.showDashboard = false;
    this.states = true;
    this.action = false;
    this.evaluation = false;
    this.setup = false;
    this.showStates = true;
  }
  viewModeHandler() {
    this.viewMode = false;
  }

  checkModeHandler() {
    this.viewMode = true;
  }

  dotView() {
    return '<h1>aa</h1>';
  }
  applyFilter(searchText: string) {
    this.dataSourceStates.filter = searchText.trim().toLowerCase();
    if (this.dataSourceStates.paginator) {
      this.dataSourceStates.paginator.firstPage();
    }
    this.dataSourceAction.filter = searchText.trim().toLowerCase();
    if (this.dataSourceAction.paginator) {
      this.dataSourceAction.paginator.firstPage();
    }
    this.dataSourceEvaluation.filter = searchText.trim().toLowerCase();
    if (this.dataSourceEvaluation.paginator) {
      this.dataSourceEvaluation.paginator.firstPage();
    }
    this.dataSourceStats.filter = searchText.trim().toLowerCase();
    if (this.dataSourceStats.paginator) {
      this.dataSourceStats.paginator.firstPage();
    }
  }
}
