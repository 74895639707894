import { UserStatusModel } from './../user-status/user.status.model';
import { UserDashboardService } from './../../../services/user-dashboard.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss'],
})
export class StoreInfoComponent implements OnInit {
  @Input('keyId') activation_key;
  @Input('userId') user_id;

  @Input('note') notes: string = '';
  @Input('collectionDate') collectionDate: Date;
  dataSaved: boolean = false;
  lastEvaluationDate: any = '';
  constructor(private userDashboardService: UserDashboardService) {}

  ngOnInit(): void {}

  storeData() {
    let test;
    if (typeof this.collectionDate === 'string') {
      test = new Date(this.collectionDate);
    } else {
      test = this.collectionDate;
    }

    let date = new Date(+test);
    const offset = date.getTimezoneOffset();
    if (offset < 0) {
      date.setHours(12, 0, 0);
    }
    let new_date: any = date;
    if (this.collectionDate) {
      new_date = date.toISOString().substring(0, 10);
    }

    const userStatusData = {
      user_id: this.user_id,
      activation_key: this.activation_key,
      star: null,
      collectionDate: this.collectionDate ? new_date : null,
      note: this.notes ? this.notes : null,
    };

    this.userDashboardService.storeStatus(userStatusData).subscribe((data) => {
      this.dataSaved = true;
      setTimeout(() => {
        this.dataSaved = false;
      }, 2000);
    });
  }
}
