import { filter } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { ColumnListModel } from './columns.model';
import { StatesService } from './../states.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'add-column.dialog',
  templateUrl: 'add-column.dialog.html',
  styleUrls: ['./add-column.dialog.scss'],
})
export class AddColumnsDialog implements OnInit {
  columnList: ColumnListModel = {
    user_id: false,
    company: false,
    date_of_birth: false,
    plan_start_date: false,
    plan_expiration_date: false,
    plan_cancelled_date: false,
    plan_type: false,
    plan_price: false,
    plan_sponsor: false,
    plan_isActive: false,
    first_visit: false,
    last_visit: false,
    last_bordereau_downloaded: false,
    appointment_set: false,
    data_analysis: false,
    data_clinical: false,
    data_stress: false,
    indexes_viewed: false,
    pdf_downloaded: false,
    phyto_viewed: false,
    phyto_ordered: false,
    reco_checked: false,
    reco_checked_number: false,
    reco_commited: false,
    reco_notified: false,
    reco_viewed: false,
    score_generated: false,
    score_id: false,
    score_viewed: false,
    score_state_createdat: false,
    questioner_domains_numbers: false,
    questioner_sent: false,
    questioner_id: false,
    questioner_state_createdat: false,
    email: false,
    campaign_id: false,
    company_id: false,
    company_name: false,
    activation_key: false,
    key_type: false,
    bilan_type: false,
    location_site: false,
    off_site: false,
    expert_call_date: false,
    expert: false,
    survey1_date: false,
    survey2_date: false,
    survey_rate: false,
    collection_date: false,
    note: false,
    score_entries_count: false,
  };
  toppings = this._formBuilder.group(this.columnList);
  constructor(
    public dialogRef: MatDialogRef<AddColumnsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private statesService: StatesService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.statesService.columnListStorage.subscribe(
      (result: ColumnListModel) => {
        if (result) {
          this.columnList = result;

          this.toppings.setValue(result);
        }
      }
    );
  }
  save() {
    let newViewObject = { name: this.data.name, value: this.toppings.value };
    this.statesService.setColumnList(newViewObject).subscribe((result) => {
      this.statesService.columnListStorage.next(this.toppings.value);
      let newViewStorageValues = this.statesService.viewsStorage.getValue();
      for (let index in newViewStorageValues) {
        if (newViewStorageValues[index].name === this.data.name) {
          newViewStorageValues[index] = newViewObject;
        }
      }
      this.statesService.viewsStorage.next(newViewStorageValues);
      this.statesService.currentViewStorage.next(newViewObject);
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  extra: string;
  name: string;
}
