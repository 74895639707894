import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

export interface Period {
  start: string;
  end: string;
}

@Injectable()
export class ExportService {
  private readonly commercialExportsEndpoint = environment.api.commercialUrl + '/exports';
  private readonly authentificationEndpoint = environment.api.authUrl + '/usage';

  private readonly kpiDataUrl: string = this.authentificationEndpoint + '/kpis';
  private readonly kpiDataExalisUrl: string = this.authentificationEndpoint + '/kpis-exalis';

  private readonly patientsDataUrl: string = this.commercialExportsEndpoint + '/patients-data';
  private readonly linearScoresUrl: string = this.commercialExportsEndpoint + '/linear-scores';
  private readonly activationCodesUrl: string = this.commercialExportsEndpoint + '/activation-keys';
  private readonly companyStatsUrl: string = this.commercialExportsEndpoint + '/company-stats';
  private readonly userPortalStatsUrl: string = this.commercialExportsEndpoint + '/user-portal-stats';

  constructor(private http: HttpClient) { }

  getDownloadableKpiData(): Observable<any> {
    return this.http.get(this.kpiDataUrl, {
      responseType: 'text',
      observe: 'response',
    });
  }

  getDownloadableKpiDataExalis(): Observable<any> {
    return this.http.get(this.kpiDataExalisUrl, {
      responseType: 'text',
      observe: 'response',
    });
  }

  getDownloadableKpiDataExalisWithDateRange(period: Period): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('start', period.start)
      .set('end', period.end);

    return this.http.get(this.kpiDataExalisUrl, {
      responseType: 'text',
      observe: 'response',
    });
  }


  getDownloadablePatientsData(): Observable<any> {
    return this.http.get(this.patientsDataUrl, {
      responseType: 'text',
      observe: 'response',
    });
  }

  getDownloadableLinearScores(): Observable<any> {
    return this.http.get(this.linearScoresUrl, {
      responseType: 'text',
      observe: 'response',
    });
  }

  getDownloadableActivationKeys(): Observable<any> {
    return this.http.get(this.activationCodesUrl, {
      responseType: 'text',
      observe: 'response',
    });
  }

  getDownloadableCompanyStats(period: Period): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('start', period.start)
      .set('end', period.end);

    return this.http.get(this.companyStatsUrl, {
      params,
      responseType: 'text',
      observe: 'response',
    });
  }

  getDownloadableUserPortalStats(period: Period): Observable<any> {
    const params: HttpParams = new HttpParams()
        .set('start', period.start)
        .set('end', period.end);

    return this.http.get(this.userPortalStatsUrl, {
      params,
      responseType: 'text',
      observe: 'response',
    });
  }
}
